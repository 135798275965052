import React, { Component } from "react";
import { Jumbotron } from "reactstrap";
import { page } from '../../services/analytics.js'
import Loading from "../Loading";
import API from '../../services/apiService';
import Error from "../Error";
import SEO from '../../components/SEO';

export default class Login extends Component {

  constructor(props) {
    super(props);

    this.cancelSignal = API.CancelToken.source();

    this.state = {
      isError: false,
      isLoading: true
    };
  }

  login = () => {
    setTimeout(() => (window.location.href = "https://dashboard.heroku.com"), 1000);
  }
  
  componentDidMount() {
    page();

    this.login();
  }

  render() {
    return (
      <div>
        <SEO title="Login" />
        { this.state.isError && <Error /> }
        {
          this.state.isLoading &&
          <Jumbotron className="mb-4 text-center">
            <Loading className="my-5 py-5" loading={true} text="Hold tight - redirecting you to Heroku dashboard" />
          </Jumbotron>
        }
      </div>
    );
  }
}