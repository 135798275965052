import React, { Component } from "react";
import { FormGroup, Label, Input, Button } from "reactstrap";
import { page } from '../../services/analytics.js';

const crypto = require('crypto');

export default class SsoLogin extends Component {

  constructor(props) {
    super(props);

    this.handleResourceIdChange = this.handleResourceIdChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);

    this.state = {
      nav_data: "eyJhZGRvbiI6IllvdXIgQWRkb24iLCJhcHBuYW1lIjoibXlhcHAiLCJhZGRvbnMiOlt7InNsdWciOiJjcm9uIiwibmFtZSI6IkNyb24ifSx7InNsdWciOiJjdXN0b21fZG9tYWlucyt3aWxkY2FyZCIsIm5hbWUiOiJDdXN0b20gRG9tYWlucyArIFdpbGRjYXJkIn0seyJzbHVnIjoieW91cmFkZG9uIiwibmFtZSI6IllvdXIgQWRkb24iLCJjdXJyZW50Ijp0cnVlfV19",
      email: process.env.REACT_APP_HEROKU_ADDON_SSO_EMAIL,
      resource_id: process.env.REACT_APP_HEROKU_ADDON_SSO_RESOURCE_ID,
      resource_token: "",
      timestamp: "",
      form_action: `${process.env.REACT_APP_API_BASE_URL}/partners/heroku/sso/login`
    };
  }

  componentDidMount() {
    page();
    this.setData(this.state.resource_id);
  }

  setData(resourceId) {
    const timestamp = Math.floor(new Date() / 1000);
    const ssoSalt = process.env.REACT_APP_HEROKU_ADDON_SSO_SALT || localStorage.getItem("HEROKU_ADDON_SSO_SALT");
    const message = `${resourceId}:${ssoSalt}:${timestamp}`;
    const resourceToken = crypto.createHash("sha1").update(message).digest("hex").toString();

    this.setState({
      resource_id: resourceId,
      resource_token: resourceToken,
      timestamp: timestamp
    });
  }

  handleResourceIdChange(event) {
    this.setData(event.target.value);
  }

  handleEmailChange(event) {
    this.setState({
      email: event.target.value
    });
  }

  render() {
    return (
      <div className="container-fluid mt-4 mb-4">
        <form method="post" action={this.state.form_action}>
          <FormGroup>
            <Label for="resource_id">Resource ID</Label>
            <Input type="text" name="resource_id" defaultValue={this.state.resource_id} onChange={this.handleResourceIdChange} /><br />
            <Label for="email">Email</Label>
            <Input type="text" name="email" value={this.state.email} onChange={this.handleEmailChange} /><br />
            <Input type="hidden" name="resource_token" value={this.state.resource_token} />
            <Input type="hidden" name="timestamp" value={this.state.timestamp} />
          </FormGroup>
          <Button color="primary">Submit</Button>
        </form>
      </div>
    );
  }
}