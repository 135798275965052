import React, { Component } from "react";
import { Fade } from "reactstrap";
import "./Loading.css";

export default class Loading extends Component {
  render() {
    let { className, size, loading, color, text } = this.props;
    let spinnerClassName = "spinner-border";

    className = "Loading " + (className ? className : "");

    if ( !color ) {
      color = "muted";
    }

    if ( size ) {
      spinnerClassName += " spinner-border-" + size;
    }

    spinnerClassName += " text-" + color;

    return (
      <Fade timeout={200} in={loading} className={className}>
        {
          loading &&
          <div className="d-flex justify-content-center">
            <div>
              <span className={ spinnerClassName } />
            </div>
            <div className="align-self-center ml-3">
              <span>{text}</span>
            </div>
          </div>
        }
      </Fade>
    );
  }
}