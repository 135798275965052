import { Component } from "react";
import Pusher from 'pusher-js';
import { baseInstance as API } from '../services/apiService';

const _ = require('lodash');

export default class PusherSubscription extends Component {
  
  constructor (props) {
    super(props);

    this.cancelSignal = API.CancelToken.source();

    const { authEndpoint, channelName } = this.props;
    this.subscribe(authEndpoint, channelName)
  }

  componentDidUpdate(prevProps) {
    if (this.props.authEndpoint === prevProps.authEndpoint && this.props.channelName === prevProps.channelName && _.isEqual(this.props.events, prevProps.events) ) return;

    this.unsubscribe();
    this.subscribe(this.props.authEndpoint, this.props.channelName);
    this.bindEvents(this.props.events);
  }

  componentDidMount() {
    const { events } = this.props;

    this.bindEvents(events);
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  subscribe(authEndpoint, channelName) {
    try {
      const that = this;
      Pusher.logToConsole = process.env.NODE_ENV !== 'production';
      this.pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
        cluster: process.env.REACT_APP_PUSHER_CLUSTER,
        forceTLS: true,
        authorizer: function(channel, options) {
          return {
            authorize: function(socketId, callback) {
              API.post(authEndpoint, {
                socket_id: socketId,
                channel_name: channel.name
              }, { withCredentials: true, cancelToken: that.cancelSignal.token }).then( response => {
                callback(false, response.data);
              } ).catch( ( error ) => {
                callback(true);
              } );
            }
          };
        }
      });

      this.channel = this.pusher.subscribe(channelName);
    } catch (error) {
      console.log(`An error occurred authorizing Pusher channel '${channelName}': `, error);
    }
  }

  unsubscribe() {
    try {
      this.cancelSignal.cancel();
      if( this.channel ) {
        this.channel.unbind();
        this.pusher.unsubscribe(this.channel);
      }
    } catch (error) {
      console.log(`An error occurred unsubscribing Pusher: `, error);
    }
  }

  bindEvents(events) {
    const { onEvent } = this.props;

    if( this.channel && events ) {
      events.forEach(event =>
        this.channel.bind(event, payload => onEvent(event, payload))
      );
    }
  }

  render() {
    return null;
  }
}