import React, { Component } from "react";
import { ArrowRightIcon } from '@primer/octicons-react';

import "./CtaLink.css";

export default class CtaLink extends Component {

  render() {
    var {text, className, ...others} = this.props

    className = "CtaLink " + className;

    return (
      <a {...others} className={className}>
        <span>{text}</span>
        <ArrowRightIcon size="small" verticalAlign='middle' />
      </a>
    );
  }
}
