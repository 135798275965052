import React, { Component } from "react";
import { Jumbotron} from "reactstrap";
import { page } from '../services/analytics.js'
import Benefits from "../components/home/Benefits";
import Security from "../components/home/Security";
import Testimonials from "../components/home/Testimonials";
import GetStarted from "../components/home/GetStarted";
import Compliance from "../components/home/Compliance";
import CtaLink from "../components/CtaLink";

import "./Home.css";

export default class Home extends Component {

  componentDidMount() {
    page();
  }
  
  render() {
    return (
      <div className="Home">
        <Jumbotron className="text-center">
          <div className="mt-3 col-sm-12 col-md-6 offset-md-3">
            <h1>Send <b>email</b> from your <b>Heroku Apps</b></h1>
              <p className="mt-4 lead">Mailer To Go allows you to send email from within your Heroku applications using your own email addresses and domains.</p>
              <div className="primary-divider" />
              <CtaLink href={ process.env.REACT_APP_SIGNUP_URL } className="btn btn-lg btn-primary" text="Get started" />
          </div>
        </Jumbotron>
        <hr/>
        <Benefits />
        <Security />
        <Testimonials />
        <GetStarted />
        <Compliance />
      </div>
    );
  }
}