import React, { Component } from "react";
import { page } from '../services/analytics.js'
import SEO from '../components/SEO';
import "./Gdpr.css";

export default class Gdpr extends Component {
  componentDidMount() {
    page();
  }

  render() {
    return (
      <div className="Gdpr mb-4 container-fluid">
        <SEO title="Privacy And GDPR FAQ" description="" />
        <div className="row">
          <div className="mt-5 mb-5 col-10 col-md-10 col-xl-10 offset-1 offset-md-1 offset-xl-1 text-justify">
            <h1>Crazy Ant Labs Privacy And GDPR FAQ</h1>
            <p><small className="text-muted">Effective as of Mar 1st, 2021</small></p>

            <h3>What Personal Information do you collect for my account?</h3>
            <p>
              If you use our service from Heroku, we collect the app's “owner_email” address and app members' email addresses, provided by Heroku in accordance with their privacy statement.
            </p>
            <p>
              When you contact our support, we collect your email address to be able to respond to your inquiries when you're offline.
            </p>
            <p>
              We use the following subprocessors to improve our service and market our products:
              <ul>
                <li>Amazon Web Services (cloud infrastructure)</li>
                <li>Salesforce (Heroku) (cloud infrastructure)</li>
                <li>Glass (support) </li>
                <li>FullStory (product & support)</li>
                <li>Google Analytics (analytics)</li>
                <li>Twitter (analytics)</li>
                <li>Taboola (analytics)</li>                
                <li>Woopra (analytics)</li>
                <li>Baremetrics (payment analytics)</li>
              </ul>
            </p>

            <h3>What kind of information do you collect? How long do you store the tracked data?</h3>
            <p>
              Mailer To Go tracks the following:
              <ul>
                <li>The actual contents of the files uploaded to your Mailer To Go service ("Customer Data").</li>
                <li>Metadata about your Customer Data that is distinct from the actual content itself ("Customer Metadata"). Customer Metadata includes file and folder names, creation and modification dates, permissions, and size information.</li>
                <li>Metadata about your account - most notably, users and their associated data (passwords, access restrictions, etc.).</li>
              </ul>
            </p>
            <p>
              We also track product usage using FullStory in order to analyze usage patterns and improve our product. Data is stored for 1 month. Confidential data is not passed on to FullStory.
            </p>

            <h3>What do you share with marketers or other advertising and non essential companies?</h3>

            <p>
              We do not sell or rent your personal data to third parties for marketing purposes. However, for data aggregation purposes we may use your non-personal data, which might be sold to other parties at our discretion. Any such data aggregation would not contain any of your personal data. We may give your personal data to third-party service providers whom we hire to provide services to us. These third-party service providers may include but are not limited to payment processors, web analytics companies, advertising networks, call centers, data management services, help desk providers, accountants, law firms, auditors, shopping cart and email service providers, and shipping companies.
            </p>

            <h3>How do we request to have our information deleted or ported to another service?</h3>

            <p>
              Simple, send us an email at support@mailertogo.com and we’ll verify your identity (we don’t want your competitors tricking us into deleting your account now, do we?) and either port or delete your data as requested.
            </p>

            <h3>If I want to request to be ‘forgotten’ or deleted, what data would we delete and would we have to likely keep for legal reasons?</h3>

            <p>
              We will remove your data from our subprocessors. Your data will remain intact in our systems until you unsubscribe from our services at which point it will be deleted.
            </p>

            <h3>Do you collect any private data that you don’t need to run your service?</h3>

            <p>
              We collect personal data sufficient to run the service for you, improve it over time, and service our customers successfully. That means we don’t collect any additional or unnecessary private data other than the information listed above.
            </p>

            <h3>Can you send us a Data Protection Agreement?</h3>

            <p>
              Of course! Just contact our support and we can get the process started for your company.
            </p>

          </div>
        </div>
      </div>
    );
  }
}
