import React, {lazy, Suspense} from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Loading from "./containers/Loading";
import Home from "./containers/Home";
import Error from "./containers/Error";
import NotFound from "./containers/NotFound";
import Unauthorized from "./containers/Unauthorized";
import Login from "./containers/auth/Login";
import Logout from "./containers/auth/Logout";
import AuthError from "./containers/auth/AuthError";
import SsoLogin from "./containers/auth/SsoLogin";
import Terms from "./containers/Terms";
import Privacy from "./containers/Privacy";
import Gdpr from "./containers/Gdpr";
import Dpa from "./containers/Dpa";
import Security from "./containers/Security";
import About from "./containers/About";
import AccountSettings from "./containers/settings/AccountSettings";
import Organizations from "./containers/Organizations";

const AppContainer = lazy(() => import("./containers/AppContainer"));

const Routes = () =>
  <Switch>
    <Route path="/" exact component={Home} />
    <Route path="/500" exact component={Error} />
    <Route path="/403" exact component={Unauthorized} />
    <Route path="/404" exact component={NotFound} />
    <Route path="/sso-login" exact component={SsoLogin} />
    <Route path="/terms-of-service" exact component={Terms} />
    <Route path="/privacy" exact component={Privacy} />
    <Route path="/security" exact component={Security} />
    <Route path="/privacy/gdpr" exact component={Gdpr} />
    <Route path="/terms-of-service/dpa" exact component={Dpa} />
    <Route path="/about" exact component={About} />
    <Route path="/auth/login" exact component={Login} />
    <Route path="/auth/logout" exact component={Logout} />
    <Route path="/auth/error" exact component={AuthError} />
    <Route path="/settings/account" exact component={AccountSettings} />
    <Route path="/organizations" exact component={Organizations} />

    <Redirect exact from="/organizations/:organizationId" to="/organizations/:organizationId/dashboard" />
    <Suspense fallback={<Loading loading={true} />}>
        <Route path="/organizations/:organizationId" component={AppContainer} />
    </Suspense>

    { /* Finally, catch all unmatched routes */ }
    <Route component={NotFound} />
  </Switch>;

export default Routes;
