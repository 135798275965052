import React, { Component } from "react";
import { Jumbotron } from "reactstrap";
import "./Benefits.css";

export default class Benefits extends Component {

  render() {
    return (
      <div className="Benefits">
        <Jumbotron className="container-fluid" id="why">
          <div className="row">
            <div className="text-center col-sm-12 col-md-6 offset-md-3 mb-5">
              <h2>What makes Mailer To Go stand out</h2>
            </div>
          </div>
          <div className="row  mt-3">
            <div className="col-sm-12 col-md-6 col-lg-4">
              <h3 className="text-primary">Email! by developers, for developers</h3>
              <p className="mt-2 mb-5">We've spent too much time dealing with other mailing services, grumbling and muttering, until we've finally decided to create a new, <b>simpler</b> and <b>better email service</b> everybody can use and enjoy.</p>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <h3 className="text-primary">Use your own domains</h3>
              <p className="mt-2 mb-5">We automatically detect your Heroku app's verified domains so you can <b>start sending emails</b> from <b>your own domains</b> within minutes.</p>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <h3 className="text-primary">Secure and reliable</h3>
              <p className="mt-2 mb-5">We work hard to make sure that our customers can focus on their unique value, knowing that their email service is rock solid!</p>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <h3 className="text-primary">Zero management</h3>
              <p className="mt-2 mb-5">
                Our service eliminates the need to purchase, maintain or manage your own mailing server. Mailer To Go takes care of everything for you.
                <br/><br/>
                Just provision Mailer To Go and start sending emails from your Heroku apps.
              </p>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <h3 className="text-primary">Fully managed</h3>
              <p className="mt-2 mb-5">Our mailing service is <b>distributed, scalable and reliable</b>. Sending emails is no longer a single point of failure.</p>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <h3 className="text-primary">Run at any scale</h3>
              <p className="mt-2 mb-5">Our products auto scales and allow you to optimize performance, costs, or balance between them.</p>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <h3 className="text-primary">Seamless integration</h3>
              <p className="mt-2 mb-5">Mailer To Go provides an <b>SMTP interface</b> for seamless integration with applications that can send email via SMTP.</p>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <h3 className="text-primary">Powered by cutting-edge technology</h3>
              <p className="mt-2 mb-5">Mailer To Go is built on top of world class cloud platforms and technologies to provide the best availability, scalability and security.</p>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <h3 className="text-primary">Meet compliance requirements</h3>
              <p className="mt-2 mb-5">We meet a variety of compliance, auditing, and accountability requirements.</p>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <h3 className="text-primary">Meet compliance requirements</h3>
              <p className="mt-2 mb-5">
                We meet a variety of compliance, auditing, and accountability requirements.
              </p>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <h3 className="text-primary">We are here for you</h3>
              <p className="mt-2 mb-5">We offer chat support in all our plans so we’ll be there to answer questions and help you with your needs.</p>
            </div>
          </div>
        </Jumbotron>
      </div>
    );
  }
}
