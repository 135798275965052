const _ = require('lodash');


export const normalizeKeys = (traits) => {
  return _.mapKeys( traits, (v,k) => _.snakeCase(k) );
}

export const page = (category: string, name: string, properties: any) => {
  if (window.analytics) {
    window.analytics.page(category, name, normalizeKeys(properties));
  }
}
export const identify = (userId: string, traits: any, context: any) => {
  if (window.analytics) {
    window.analytics.identify(userId, normalizeKeys(traits), context);
  }
  window.glassUserInfo = {
    user: {
      id: userId,
      email: traits['email'],
      name: traits['name']
    }
  };
}
export const group = (groupId: string, traits: any, context: any) => {
  if (window.analytics) {
    window.analytics.group(groupId, normalizeKeys(traits), context);
  }
}
export const track = (name: string, properties: any, context: any) => {
  if (window.analytics) {
    window.analytics.track(name, normalizeKeys(properties));
  }
}
export const reset = () => {
  if (window.analytics) {
    window.analytics.reset();
  }
}
export const ready = (callback: any) => {
  if (window.analytics) {
    window.analytics.ready(callback);
  }
}
