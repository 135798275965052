import React from 'react';
import ReactDOM from 'react-dom';
import './themes/dark/index.scss';
import './themes/auto/index.scss';
// Light must be the last one here.
import './themes/light/index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import ScrollToTop from './components/ScrollToTop';
import { BrowserRouter as Router } from "react-router-dom";
import Moment from 'react-moment';

// Start Moment's pooled timer which runs every 60 seconds
Moment.startPooledTimer(6000);

ReactDOM.render(
  <Router>
    <ScrollToTop>
      <App />
    </ScrollToTop>
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
