import axios from 'axios';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

var instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}`
});

instance.CancelToken = axios.CancelToken;
instance.isCancel = axios.isCancel;

var baseInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}`
});

baseInstance.CancelToken = axios.CancelToken;
baseInstance.isCancel = axios.isCancel;

instance.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (!axios.isCancel(error)) {
    console.log("API Error: ", error);
    const message = error.response && error.response.data ? error.response.data.message : error.message;
    const toastId = message ? message : uuidv4();
    if(! toast.isActive(toastId)) {
      toast.warning("Oops, something went wrong... " + (message ? message : ""), {
        className: "alert alert-danger",
        toastId: toastId,
        icon: false,
        autoClose: true
      });
    }
  }
  return Promise.reject(error);
});

export { instance as default, baseInstance };
