import React, { Component } from "react";
import { Media, Nav, Navbar, NavItem, NavbarToggler, Collapse } from "reactstrap";
import Routes from "./Routes";
import { Link, withRouter, Route } from "react-router-dom";
import { matchPath } from "react-router";
import { ToastContainer } from 'react-toastify';
import ToastifyCloseButton from './components/ToastifyCloseButton';
import AccountNavItem from './components/AccountNavItem';
import ThemeNavItem from './components/ThemeNavItem';
import KeyboardShortcutsDialog from "./components/KeyboardShortcutsDialog";
import SEO from './components/SEO';

import "./App.css";

const _ = require('lodash');
const Mousetrap = require('./libs/mousetrap-lib');

class App extends Component {

  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      isMenuCollapsed: true,
      appName: null,
      addon: null,
      isShortcutsModalOpen: false
    };
  }

  async componentDidMount() {
    this.props.history.listen( location => {
      this.setOrganizationId(location);
    });

    // Extract organization ID to check if we should provide app links
    this.setOrganizationId(this.props.location);

    Mousetrap.bind('s',(e) => {
      if( window.Intercom ) { e.preventDefault(); window.Intercom('showNewMessage')}
    })
    Mousetrap.bind('?', (e) => {
      this.toggleShortcutsModal();
    });
  }

  componentWillUnmount() {
    Mousetrap.unbind('s');
    Mousetrap.unbind('?');
  }

  toggleShortcutsModal = () => {
    this.setState({ isShortcutsModalOpen: !this.state.isShortcutsModalOpen });
  };

  setOrganizationId = (location) => {
    const match = matchPath(location.pathname, { 
      path: "/organizations/:organizationId", 
      exact: false
    });
    this.setState({
      organizationId: _.get(match, 'params.organizationId')
    });
  }

  toggleNavbar() {
    this.setState({
      isMenuCollapsed: !this.state.isMenuCollapsed
    });
  }

  render() {
    const year = new Date().getFullYear();
    
    return (
      <Route render={({ location }) => {
        let isSignup = location.pathname.startsWith('/register');
        let isLogin = location.pathname.startsWith('/auth/');
        let isPricing = location.pathname.startsWith('/pricing');

        return (
          <div className="App">
            <SEO />
            <Navbar className="border-bottom d-flex justify-content-between" color="white" light expand="lg">
              <Link to="/" className="navbar-brand">
                <Media>
                  <Media left className="mr-2">
                    <Media object src={`${process.env.PUBLIC_URL}/images/logo.svg`} alt="Mailer To Go" />
                  </Media>
                  <Media body className="brand-name">
                    Mailer To Go
                  </Media>
                </Media>
              </Link>
              <NavbarToggler onClick={this.toggleNavbar} />
              <Collapse isOpen={!this.state.isMenuCollapsed} navbar>
                <Nav className="navbar-nav ml-auto" navbar>
                    <NavItem>
                      <Link to="tel:18662427811" className="nav-link">
                        1 (866) 242-7811
                      </Link>
                    </NavItem>
                    <ThemeNavItem />
                    <AccountNavItem signup={!isPricing && !isSignup && !isLogin} login={!isLogin} />
                  </Nav>
              </Collapse>
            </Navbar>
            <Routes />
            <footer className="footer container-fluid mt-3 mb-3">
              <div className="row align-items-center justify-content-xl-between">
                <div className="col-xl-12">
                  <Nav className="justify-content-center">
                    <NavItem>
                      <Link to="/terms-of-service">
                        Terms
                      </Link>
                    </NavItem>
                    <NavItem>
                      <Link to="/privacy" className="ml-3">
                        Privacy
                      </Link>
                    </NavItem>
                    <NavItem className={isSignup ? "d-none" : ""}>
                      <Link to="/security" className="ml-3">
                        Security
                      </Link>
                    </NavItem>
                    <NavItem className={isSignup ? "d-none" : ""}>
                      <a target="_blank" href="https://status.crazyantlabs.com/" rel="noopener noreferrer" className="ml-3">
                        Status
                      </a>
                    </NavItem>
                    <NavItem className={isSignup ? "d-none" : ""}>
                      <a className="ml-3"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://devcenter.heroku.com/articles/mailertogo"
                      >
                        Docs
                      </a>
                    </NavItem>
                    <NavItem>
                      <a target="_blank"
                        href="mailto:support@mailertogo.com"
                        className="contact-us-cta ml-3"
                        rel="noopener noreferrer"
                        onClick={(event) => { if( window.Intercom ) { event.preventDefault(); window.Intercom('showNewMessage'); } }}
                      >
                        Contact us
                      </a>
                    </NavItem>
                    <NavItem className={isSignup ? "d-none" : ""}>
                      <a className="ml-3"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://elements.heroku.com/addons/mailertogo#pricing"
                      >
                        Pricing
                      </a>
                    </NavItem>
                    <NavItem className={isSignup ? "d-none" : ""}>
                      <a className="ml-3"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://blog.mailertogo.com"
                      >
                        Blog
                      </a>
                    </NavItem>
                    <NavItem className={isSignup ? "d-none" : ""}>
                      <Link to="/about" className="ml-3">
                        About
                      </Link>
                    </NavItem>
                  </Nav>
                </div>
              </div>
              <div className="row align-items-center justify-content-xl-between mt-1">
                <div className="col-xl-12">
                  <div className="copyright text-center text-muted">
                    © {year}{' '}
                    {
                      isSignup ? "Crazy Ant Labs" : 
                      <a href="https://crazyantlabs.com" target="_blank" rel="noopener noreferrer">
                        Crazy Ant Labs
                      </a>
                    }
                  </div>
                </div>
              </div>
            </footer>
            <ToastContainer closeButton={<ToastifyCloseButton />} hideProgressBar={true} position="top-center"/>
            <KeyboardShortcutsDialog isModalOpen={this.state.isShortcutsModalOpen} toggleModal={this.toggleShortcutsModal}/>
          </div>
        );
      }} />
    );
  }
}

export default withRouter(App);
