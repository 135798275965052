import React, { Component } from "react";

const pathToImages = require.context('../images', true);

export default class Picture extends Component {
  render() {
    const { className, src, alt } = this.props;

    return (
      <picture>
        <source srcSet={pathToImages(src + ".webp", true).default} type="image/webp" alt={alt} />
        <img className={className} src={pathToImages(src + ".png", true).default} alt={alt} />
      </picture>
    );
  }
}
