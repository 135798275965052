import React, { Component } from "react";
import { Jumbotron } from "reactstrap";
import { page } from '../services/analytics.js'
import GetStarted from "../components/home/GetStarted";
import Compliance from "../components/home/Compliance";
import SEO from '../components/SEO';

import "./About.css";

export default class About extends Component {

  componentDidMount() {
    page();
  }

  render() {
    return (
      <div className="About">
        <SEO title="About Us" description="Crazy Ant Labs was founded by Moty Michaely and Saggi Neumann in early 2019" />
        <Jumbotron className="text-center container-fluid mb-2">
          <div className="row">
            <div className="mt-3 col-sm-8 col-md-8 offset-md-2">
              <h1>About us</h1>
              <div className="primary-divider" />
            </div>
          </div>
        </Jumbotron>
        <div className="container-fluid">
          <div className="row">
            <div className="mb-5 col-10 col-md-10 col-xl-10 offset-1 offset-md-1 offset-xl-1 text-justify">
              <p>
              Crazy Ant Labs was founded by Moty Michaely and Saggi Neumann in early 2019 with the mission to build quality fully-managed cloud based add-ons for various platforms in order to help developers and users focus on what's really important instead of handling and managing peripheral services.
              </p>
              <p>
              Prior to founding Crazy Ant Labs, Saggi and Moty were two of the founders of Xplenty, a cloud data integration company that was acquired in late 2018. Xplenty is the first Heroku ETL add-on.
              </p>
            </div>
          </div>
        </div>
        <GetStarted />
        <Compliance />
      </div>
    );
  }
}