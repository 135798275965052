import React, { Component } from "react";
import { Link } from "react-router-dom";
import { LocationIcon } from '@primer/octicons-react';
import { page } from '../services/analytics.js'
import "./NotFound.css";

export default class NotFound extends Component {
  componentDidMount() {
    page(); // Route has changed, call analytics.page()
  }

  render() {
    return (
      <div className="bg-white py-5">
        <div className="text-center py-5">
          <LocationIcon size={60} className="text-danger mb-4" />
          <h5>Sorry, we couldn't find this page.</h5>
          <p>Perhaps head back <Link to="/">home</Link>?</p>
        </div>
      </div>
    );
  }
}
