import React, { Component } from "react";
import SEO from '../components/SEO';

import { page } from '../services/analytics.js'

import OrganizationsCard from './settings/OrganizationsCard';

import './Organizations.css'

export default class Organizations extends Component {

  async componentDidMount() {
    page();
  }

  render() {
    return (
      <div className="Organizations">
        <SEO title="Organizations" />
        <div className="container mt-4 mb-4">
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <OrganizationsCard className="mb-3" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
