import React, { Component } from "react";
import { AlertIcon } from '@primer/octicons-react';
import { page } from '../services/analytics.js'
import "./Error.css";

export default class Error extends Component {

  componentDidMount() {
    page();
  }
  
  render() {
    return (
      <div className="bg-white py-5">
        <div className="text-center py-5">
          <AlertIcon size={60} className="text-danger mb-4" />
          <h5>Something went wrong.</h5>
          <p>If you're using an ad-blocker, please try disabling it.</p>
        </div>
      </div>
    );
  }
}