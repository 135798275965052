import React, { Component } from "react";
import { page } from '../services/analytics.js'
import SEO from '../components/SEO';
import "./Privacy.css";

export default class Privacy extends Component {
  componentDidMount() {
    page();
  }

  render() {
    return (
      <div className="Privacy mb-4 container-fluid">
        <SEO title="Privacy Policy" description="Crazy Ant Labs privacy policy" />
        <div className="row">
          <div className="mt-5 mb-5 col-10 col-md-10 col-xl-10 offset-1 offset-md-1 offset-xl-1 text-justify">
            <h2>Privacy Policy</h2>
            <p><small className="text-muted">Effective as of May 1st, 2021</small></p>
            <h3>What information do we collect?</h3>
            <p>
            We receive and store any information you knowingly provide to us.  For example, through the registration process and/or through your account settings, we collect personal information such as your name and email address.
            </p>
            <p>Mailer To Go also tracks the following:
            <ul>
                <li>The actual contents of the files uploaded to your Mailer To Go service ("Customer Data").</li>
                <li>Metadata about your Customer Data that is distinct from the actual content itself ("Customer Metadata"). Customer Metadata includes file and folder names, creation and modification dates, permissions, and size information, users and their associated data (passwords, access restrictions, etc.).</li>
                <li>Metadata about your account, not tied to any specific file ("Account Metadata"). Account Metadata includes general account settings, users and their associated data (passwords, access restrictions, etc.)
                </li>
                <li>Usage information customarily logged by Mailer server software, including the date and time of your visit, the originating IP address, and access to files. We also get usage data from third parties such as Google Analytics, who may place tracking pixels in our website. Collectively, we call this "Usage Data".</li>
                <li>Email addresses of users provided by Heroku ("Registration, Billing, and Administration Data”).</li>
                <li>Information from users who communicate with us via e-mail or our website for example to ask for product support ("Correspondence Data").</li>
            </ul>
            </p>
            <p>
            In addition, whenever you interact with Our website or Service, your IP address, browser and device information, "Cookies" and pages requested are automatically logged. “Cookies” are identifiers we transfer to your browser or device that allow us to recognize your browser or device and tell us how and when pages and features in our Services are visited and by how many people. You may be able to change the preferences on your browser or device to prevent or limit your device’s acceptance of cookies, but this may prevent you from taking advantage of some of our features.
            </p>
            
            <h3>Cookies</h3>
            <p>
            Our Site may place and access certain first-party Cookies on your computer or device. First party Cookies are those placed directly by Us and are used only by Us. We use Cookies to facilitate and improve your experience of Our Site and to provide and improve Our products and services.  By using Our Site you may also receive certain third party Cookies on your computer or device. Third-party Cookies are those placed by websites, services, and/or parties other than Us. We use third-party Cookies on Our Site for advertising and analytics. In addition, Our Site uses analytics services which also use Cookies. Website analytics refers to a set of tools used to collect and analyse usage statistics, enabling us to better understand how people use Our Site. Our site uses a third-party cookie management tool, which users located in the EU can disable any non-essential cookies. Users outside the EU can enable or disable Cookies in their internet browser.
            </p>
            
            <h3>How is information being used?</h3>
            <p>
                <ul>
                    <li>"Customer Data" is stored securely and may only be accessed by users who have been given the appropriate permissions to that Customer Data by someone with administrative permissions on the account. We will not access this data for any other purpose, except as provided below.</li>
                    <li>"Customer Metadata" and "Account Metadata" are used by our software systems to provide the Service and may be displayed, subject to our permissions controls, to users on the account.</li>
                    <li>"Usage Data" and "Correspondence Data" are used to help us understand how the service and our websites are being used and to help us improve our websites and the service.</li>
                    <li>"Registration, Billing, and Administration Data" is used to notify you about important service-related issues. Registration, Billing, and Administration Data will also be used to communicate with you regarding the Service. We communicate such things as announcements of new features, changes to Terms of Use/Privacy Policy, requests for feedback on the quality of the Service, information about pricing changes or systems outages, and other Service-related announcements. We may use a third-party service for purposes of sending these communications, and so names and email addresses that you provide us may be transferred to such a third-party service. After you stop using the service, we may use such emails to communicate offers to you to resume use of the service; all such communications will provide you with an opportunity to opt out of future communications.</li>
                </ul>
            </p>
            
            <h3>Our Access To Your Data</h3>
            <p>
                <ul>
                    <li>Except as required by law, the only other parties with whom Crazy Ant Labs might share information we collect are: (a) our contractors and service providers (e.g. payment processors, email list providers) all of whom are bound to terms of confidentiality that are at least as stringent as those stated in this Privacy Policy, and such sharing only occurs as described in this Privacy Policy; or (b) other parties as authorized in this Privacy Policy or the Terms of Service (e.g., to administrators as part of "Registration, Billing, and Administration Data”) or at your direction (e.g., sharing files with others).</li>
                    <li>We have implemented controls designed to prevent our employees or contractors from improperly using your Customer Data for purposes other than those set forth in this Privacy Policy. Our employees and contractors will not access your Customer Data unless explicitly authorized to do so by you, e.g. as part of troubleshooting an issue with your account.</li>
                    <li>However, as is reasonably necessary to facilitate provision of the Service, employees and contractors of Crazy Ant Labs may have access to your Customer Metadata, Account Metadata, Usage Data, Registration, Billing, and Administration Data, and Correspondence Data, and may use that to communicate with you or improve the service. For example, we may use the total amount of data you’ve stored to recommend you switch to a higher or lower plan tier.</li>
                </ul>
            </p>
            
            <h3>Is your personal Information secure?</h3>
            <p>
            Your account is protected by a password for your privacy and security.  You must prevent unauthorized access to your account and personal information by selecting and protecting your password appropriately and limiting access to your computer or device and browser by signing off after you have finished accessing your account.</p>
            <p>
            We endeavour to protect the privacy of your account and other personal information we hold in our records, but unfortunately, we cannot guarantee complete security.  Unauthorized entry or use, hardware or software failure, and other factors, may compromise the security of user information at any time.
            </p>
            
            <h3>Can I opt out of communications?</h3>
            <p>
            You may opt in or out of communications related to our offers and information about our new services at any time by sending an email to support@mailertogo.com.
            </p>

            <h3>European Union Privacy Notice</h3>
            <p>
                <ul>
                <li>Crazy Ant Labs is committed to compliance with the EU General Data Privacy Regulation (GDPR).</li>

                <li>If you are a resident of the European Union or the United Kingdom, then you have additional privacy rights which are guaranteed by the GDPR and other EU privacy legislation. The responsibility for effecting those rights varies depending on your relationship to Crazy Ant Labs:</li>

                <li>The responsibility for effecting those rights varies depending on your relationship to Crazy Ant Labs:
                    <ul>
                         <li>If you are a Crazy Ant Labs customer, then Crazy Ant Labs is a “data controller” for information about your account, such as your address, billing information, and other account metadata. For the data stored in your account, Crazy Ant Labs is a “data processor”, acting at your direction in processing the data.</li>
                         <li>If you are not a Crazy Ant Labs customer, but you believe that someone else has stored or processed your personal information using Crazy Ant Labs, then Crazy Ant Labs would be a “data processor” working for that third party. The party that uploaded your information to Crazy Ant Labs would be the “data controller” for that data. Crazy Ant Labs may also act as a data processor to send notifications, including email, to third parties at the direction of its customers, such as when a customer is sharing files with a third party. In this case it is the responsibility of the data controller (Crazy Ant Labs’s customer, not Crazy Ant Labs) to obtain consent for this communication.</li>
                    </ul>
                </li>
                <li>If you have questions or concerns about personal information in data for which Crazy Ant Labs is a data processor (i.e., most data that is uploaded by a user to Crazy Ant Labs), we encourage you to communicate with the data controller for the data. If you make EU/GDPR rights requests to Crazy Ant Labs regarding data where Crazy Ant Labs is the data processor, we will refer any such request to the relevant data controller, who is the party primarily responsible for implementation of those rights.</li>

                <li>For information for which Crazy Ant Labs is a data controller (e.g., address, billing information, and other account metadata), if you are an EU resident then you have the following rights with respect to that data:
                    <ul>
                     <li>You have a right to know what data is being processed, why it is being processed, and how long it will be retained, as described above in “What information do we collect?”, and “How is information being used?”;</li>
                     <li>You have a right to ask us to correct or delete data that you think is incorrect;</li>
                     <li>You have the right to lodge a complaint with the supervisory authority (for more information, see the next section on US-EU Data Privacy Shield Framework);</li>
                     <li>You have a right to request a copy of the data, although Crazy Ant Labs may charge you a reasonable fee for it;</li>
                     <li>You have a right to request that we delete this data, although our obligation to completely delete the data may be limited by legal requirements. For example, we will maintain a record that we deleted the data, including a record of your contact information, so that we can demonstrate that it was done at a later date;</li>
                     <li>You have a right to request a copy of the data, although in many cases we will refer you to your account settings page, which holds the majority of this type of data.</li>
                     <li>Crazy Ant Labs acknowledges that individuals have the right to access the personal information/data that we maintain about them, in our function as a data controller. An EU individual who seeks access, or who seeks to correct, amend, or delete inaccurate data, should direct his query to their Client Services manager or email privacy@crazyantlabs.com. Crazy Ant Labs will respond within a reasonable timeframe, and in no event greater than thirty days. Please be aware that we may refer you to the data controller, for data where Crazy Ant Labs is merely the processor.</li>
                     <li>Crazy Ant Labs’ servers are primarily located in the United States, although Crazy Ant Labs may provide options to use servers in other locations. Our Terms of Service also include a Data Processing Addendum which also provides additional legal structure for this transfer of information to the United States or other jurisdictions.</li>
                     <li>Crazy Ant Labs also uses technical support staff located in non-EU countries outside the United States. Crazy Ant Labs has entered into confidentiality agreements with all such personnel. Your information is only transferred to such countries when our personnel there would access it in response to a technical support request from the data controller (i.e., an Mailer To Go account owner). Such transfers are governed by the standard contractual clauses, which you agree to as part of the terms of service, and which we have entered into with our support staff to ensure adequate safeguards for such transfers.</li>
                     <li>Crazy Ant Labs has applied reasonable technological measures in order to ensure the security of data stored on Crazy Ant Labs. You can read more about these technological and operational measures in our security page. Because Crazy Ant Labs does not know what data you upload via the service, it is your responsibility to ensure that those technological and operational measures are appropriate for the categories of data that you are uploading.</li>
                    </ul>
                </li>

                </ul>
            </p>
            
            <h3>Changes To This Privacy Policy</h3>
            <p>
            We reserve the right to modify this Privacy Policy at any time. Changes and clarifications will take effect immediately upon their posting on the website. 
            </p>
          </div>
        </div>
      </div>
    );
  }
}
