import React, { Component } from "react";
import { Card, CardBody } from "reactstrap";
import { CheckIcon, ShieldCheckIcon } from '@primer/octicons-react';
import CtaLink from "../CtaLink.js";
import "./Security.css";

export default class Security extends Component {

  render() {
    return (
      <div className="Security">
        <div className="container-fluid mb-5 pl-5 pr-5">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-6">
              <Card className="highlight">
                <CardBody className="p-5">
                  <h4 className="font-weight-bold mb-3">Security and protection</h4>
                  <div className="pt-3 d-flex flex-row">
                    <span><CheckIcon size="small" verticalAlign='middle' className="text-green mr-2"/></span>
                    <div className="flex-grow-1">
                    We use SSL/TLS encryption on all our websites and microservices satisfying the highest security and data protection standards.
                    </div>
                  </div>
                  <div className="pt-3 d-flex flex-row">
                    <span><CheckIcon size="small" verticalAlign='middle' className="text-green mr-2"/></span>
                    <div className="flex-grow-1">
                    The data you store on Mailer To Go is encrypted in transit and at rest on the server side.
                    </div>
                  </div>
                  <div className="pt-3 d-flex flex-row">
                    <span><CheckIcon size="small" verticalAlign='middle' className="text-green mr-2"/></span>
                    <div className="flex-grow-1">
                    Our physical infrastructure is hosted on Amazon Web Services' (AWS) data centers.
                    </div>
                  </div>
                  <div className="pt-3 d-flex flex-row">
                    <span><CheckIcon size="small" verticalAlign='middle' className="text-green mr-2"/></span>
                    <div className="flex-grow-1">
                    AWS Security Groups (virtual firewalls) are utilized to restrict access to our network from external networks and between systems internally.
                    </div>
                  </div>
                  <div className="pt-3 d-flex flex-row">
                    <span><CheckIcon size="small" verticalAlign='middle' className="text-green mr-2"/></span>
                    <div className="flex-grow-1">
                    Backend access to the OS level and AWS console is limited to Crazy Ant Labs staff and requires key authentication and multi factor authentication.
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6 pl-5 pr-5 pt-4 mb-5">
              <ShieldCheckIcon name="shield" size="large" className="text-green security-icon"/>
              <h2 className="mt-4">Security</h2>
              <p className="mt-3">
                We understand the sensitivity of your data and we take all the necessary steps to safeguard it. 
                Keeping our services secure is fundamental to the nature of our business. 
                That's why security is our number one priority.
              </p>
              <CtaLink href="/security" text="Learn more about security" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
