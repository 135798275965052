import React, { Component } from "react";
import { page } from '../services/analytics.js'
import "./Dpa.css";
import SEO from '../components/SEO';

export default class Dpa extends Component {
  componentDidMount() {
    page();
  }

  render() {
    return (
      <div className="Dpa mb-4 container-fluid">
        <SEO title="Data Processing Addendum" description="Data Processing Addendum for Crazy Ant Labs" />
        <div className="row">
          <div className="mt-5 mb-5 col-10 col-md-10 col-xl-10 offset-1 offset-md-1 offset-xl-1 text-justify">

            <h2>Crazy Ant Labs Data Processing Addendum</h2>
            <p><small className="text-muted">Effective as of April 13, 2020</small></p>

            <p>This Data Protection Addendum (“<b>Addendum</b>”) forms part of the Terms of Use (“<b>Terms of Use</b>”) between: (i) Crazy Ant Labs (“<b>Crazy Ant Labs</b>”) and (ii) “Customer” as that term is defined in the Terms of Use acting on its own behalf and as agent for each Customer Affiliate.</p>
            <p>The terms used in this Addendum shall have the meanings set forth in this Addendum. Capitalized terms not otherwise defined herein shall have the meaning given to them in the Terms of Use. Except as modified below, the terms of the Terms of Use shall remain in full force and effect.</p>
            <p>In consideration of the mutual obligations set out herein, the parties hereby agree that the terms and conditions set out below shall be added as an Addendum to the Terms of Use to govern processing by Crazy Ant Labs of any Customer Data that is subject to the EU General Data Protection Regulation. Except where the context requires otherwise, references in this Addendum to the Terms of Use are to the Terms of Use as amended by, and including, this Addendum.</p>
            <h4><b>1. Definitions</b></h4>
            <p>1.1 In this Addendum, the following terms shall have the meanings set out below and cognate terms shall be construed accordingly:</p>
            <p>1.1.1 “<b>Applicable Laws</b>” means (a) European Union or Member State laws with respect to any EU Customer Personal Data in respect of which any Customer or its Affiliates is subject to EU Data Protection Laws; and (b) any other applicable law with respect to any EU Customer Personal Data in respect of which any Customer or its Affiliates is subject to any other EU Data Protection Laws;</p>
            <p>1.1.2 “<b>Customer Affiliate</b>” means an entity that owns or controls, is owned or controlled by or is or under common control or ownership with Customer, where control is defined as the possession, directly or indirectly, of the power to direct or cause the direction of the management and policies of an entity, whether through ownership of voting securities, by contract or otherwise;</p>
            <p>1.1.3 “<b>EU Customer Personal Data</b>” means any Personal Data Processed by Crazy Ant Labs on behalf of a Customer, its Affiliates, agents, or customers, pursuant to or in connection with the Terms of Use, where such Personal Data is subject to the EU Data Protection Laws;</p>
            <p>1.1.4 “<b>EEA</b>” means the European Economic Area;</p>
            <p>1.1.5 “<b>EU Data Protection Laws</b>” means EU Directive 95/46/EC, as transposed into domestic legislation of each Member State and as amended, replaced or superseded from time to time, including by the GDPR and laws implementing or supplementing the GDPR;</p>
            <p>1.1.6 “<b>GDPR</b>” means EU General Data Protection Regulation 2016/679;</p>
            <p>1.1.7 “<b>Restricted Transfer</b>” means:</p>
            <p>1.1.7.1 a transfer of EU Customer Personal Data from any Customer or its Affiliates to Crazy Ant Labs; or</p>
            <p>1.1.7.2 an onward transfer of EU Customer Personal Data from Crazy Ant Labs to a subprocessor,</p>
            <p>in each case, where such transfer would be prohibited by EU Data Protection Laws in the absence of the Standard Contractual Clauses to be established under section 11.1; however, for the avoidance of doubt, transfers pursuant to the US-EU Data Privacy Shield, or any successor or similar program that obviates the need for the Standard Contractual Clauses, are not Restricted Transfers;</p>
            <p>1.1.8 “<b>Services</b>” means the services described in section 2 of the Terms of Use;</p>
            <p>1.1.9 “<b>Standard Contractual Clauses</b>” means the contractual clauses set out in Annex 2, amended as indicated (in square brackets and italics) in that Annex and under section 12.3;</p>
            <p>1.1.10 “<b>Subprocessor</b>” means any person (including any third party, but excluding an employee of Crazy Ant Labs) appointed by or on behalf of Crazy Ant Labs to Process Personal Data on behalf of any Customer or its Affiliates in connection with the Terms of Use; and</p>
            <p>1.2 The terms, “<b>Commission</b>”, “<b>Controller</b>”, “<b>Data Subject</b>”, “<b>Member State</b>”, “<b>Personal Data</b>”, “<b>Personal Data Breach</b>”, “<b>Processing</b>” and “<b>Supervisory Authority</b>” shall have the same meaning as in the GDPR, and their cognate terms shall be construed accordingly.</p>
            <h4><b>2. Processing of EU Customer Personal Data</b></h4>
            <p>2.1 Crazy Ant Labs shall not Process EU Customer Personal Data other than on the relevant Customer or its Affiliates’ documented instructions unless Processing is required by Applicable Laws to which the relevant Crazy Ant Labs is subject, in which case Crazy Ant Labs shall to the extent permitted by Applicable Laws inform the relevant Customer or its Affiliates of that legal requirement before the relevant Processing of that Personal Data.</p>
            <p>2.2 Each Customer or its Affiliates:</p>
            <p>2.2.1 instructs Crazy Ant Labs (and authorizes Crazy Ant Labs to instruct each Subprocessor) to:</p>
            <p>2.2.1.1 Process EU Customer Personal Data; and</p>
            <p>2.2.1.2 in particular, transfer EU Customer Personal Data to any country or territory,</p>
            <p>as reasonably necessary for the provision of the Services and consistent with the Terms of Use; and</p>
            <p>2.2.2 warrants and represents that it is and will at all relevant times remain duly and effectively authorized to give the instruction set out in section 2.2.1 on behalf of each relevant Customer Affiliate.</p>
            <p>2.2.3 The instruction set forth in section 2.2.1 pertains to any reasonable processing necessary to provide the Services as described in the Terms of Service, and includes any instructions provided by Customer within the administered console of its Crazy Ant Labs account.</p>
            <p>2.3 <i>Authorization by Third Party Controller.</i> If the European Data Protection Legislation applies to the processing of EU Customer Personal Data and Customer is a processor, Customer warrants to Crazy Ant Labs that Customer’s instructions and actions with respect to that EU Customer Personal Data, including its appointment of Crazy Ant Labs as another processor, have been authorized by the relevant controller.</p>
            <p>2.4 <i>Description of processing</i>. Annex 1 to this Addendum sets out certain information regarding the Crazy Ant Labs’ Processing of the EU Customer Personal Data as required by article 28(3) of the GDPR (and, possibly, equivalent requirements of other EU Data Protection Laws). Nothing in Annex 1 (including as amended pursuant to this section 2.3) confers any right or imposes any obligation on any party to this Addendum.</p>
            <h4><b>3. Crazy Ant Labs Personnel</b></h4>
            <p>Crazy Ant Labs shall take reasonable steps to ensure the reliability of any employee, agent or contractor of any Crazy Ant Labs who may have access to the EU Customer Personal Data, ensuring in each case that access is limited to those individuals who need to know / access the relevant EU Customer Personal Data, as necessary for the purposes of the Terms of Use, and to comply with Applicable Laws in the context of that individual’s duties to Crazy Ant Labs, ensuring that all such individuals are subject to confidentiality undertakings or professional or statutory obligations of confidentiality.</p>
            <h4><b>4. Security</b></h4>
            <p>4.1 Crazy Ant Labs will provide the technological safeguards that are described in its <a href="https://mailertogo.com/security">Security Page</a>.</p>
            <p>4.2 Customer shall review the technological safeguards stated in section 4.1 to ensure those safeguards constitute a level of security appropriate to the EU Customer Personal Data, including, as appropriate, the measures referred to in Article 32(1) of the GDPR.</p>
            <p>4.3 In assessing the appropriate level of security, Customer shall take account in particular of the risks that are presented by Processing, in particular from a Personal Data Breach.</p>
            <h4><b>5. Subprocessing</b></h4>
            <p>5.1 Each Customer or its Affiliates authorises Crazy Ant Labs to appoint (and permit each Subprocessor appointed in accordance with this section 6 to appoint) Subprocessors in accordance with this section 5 and any restrictions in the Terms of Use.</p>
            <p>5.2 Crazy Ant Labs may continue to use those Subprocessors already engaged by Crazy Ant Labs as at the date of this Addendum, subject to Crazy Ant Labs in each case as soon as practicable meeting the obligations set out in section 5.4.</p>
            <p>5.3 Customer generally authorizes Crazy Ant Labs to use any other third parties as subprocessors. Crazy Ant Labs shall give Customer prior written notice of the appointment of any new Subprocessor, including full details of the Processing to be undertaken by the Subprocessor, at <a href="https://mailertogo.com/privacy/gdpr">https://mailertogo.com/privacy/gdpr</a>. If, within seven (7) days of receipt of that notice, Customer notifies Crazy Ant Labs in writing of any objections (on reasonable grounds) to the proposed appointment:</p>
            <p>5.3.1 Crazy Ant Labs shall not appoint (or disclose any EU Customer Personal Data to) that proposed Subprocessor until reasonable steps have been taken to address the objections raised by any Customer or its Affiliates and Customer has been provided with a reasonable written explanation of the steps taken.</p>
            <p>5.3.2 If Crazy Ant Labs determines it is not commercially reasonable to provide the Services without the use of the proposed Subprocessor, Crazy Ant Labs may terminate the Customer Account upon thirty (30) days’ notice, and refund any payment made for services not fully rendered as of the termination date. Except as modified by this section such termination shall otherwise occur pursuant to Section “Termination” of the Terms of Service.</p>
            <p>5.4 With respect to each Subprocessor, Crazy Ant Labs shall:</p>
            <p>5.4.1 before the Subprocessor first Processes EU Customer Personal Data (or, where relevant, in accordance with section 5.2), carry out adequate due diligence to ensure that the Subprocessor is capable of providing the level of protection for EU Customer Personal Data required by the Terms of Use;</p>
            <p>5.4.2 ensure that the arrangement between Crazy Ant Labs and the relevant intermediate Subprocessor is governed by a written contract including terms which offer at least the same level of protection for EU Customer Personal Data as those set out in this Addendum and meet the requirements of article 28(3) of the GDPR;</p>
            <p>5.4.3 if that arrangement involves a Restricted Transfer, ensure that the Standard Contractual Clauses are at all relevant times incorporated into the agreement between Crazy Ant Labs and the Subprocessor, or before the Subprocessor first Processes EU Customer Personal Data procure that it enters into an agreement incorporating the Standard Contractual Clauses with the relevant Customer or its Affiliates(s) (and Customer shall procure that each Customer Affiliate party to any such Standard Contractual Clauses co-operates with their population and execution); and</p>
            <p>5.4.4 provide to Customer for review such copies of the Crazy Ant Labs’ agreements with Subprocessors (which may be redacted to remove confidential commercial information not relevant to the requirements of this Addendum) as Customer may request from time to time.</p>
            <p>5.5 Crazy Ant Labs shall ensure that each Subprocessor performs the obligations under sections 2.1, 3, 4, 6.1, 7.2, 8 and 10.1, as they apply to Processing of EU Customer Personal Data carried out by that Subprocessor, as if it were party to this Addendum in place of Crazy Ant Labs.</p>
            <p>5.6 To the extent the Standard Contractual Clauses are required by law to be entered into directly between Customer and the Subprocessor, then Customer authorizes Crazy Ant Labs, as Customer’s attorney-in-fact, to enter into the Standard Contractual Clauses on Customer’s behalf with Subprocessor.</p>
            <h4><b>6. Data Subject Rights</b></h4>
            <p>6.1 Customer has reviewed the Crazy Ant Labs technical and organizational measures to ensure they are appropriate to the categories of EU Customer Personal Data being stored by Customer using the Service. Because Crazy Ant Labs does not know what categories of personal data will be stored using the service, this obligation must be fulfilled by Customer.</p>
            <p>6.2 Crazy Ant Labs shall:</p>
            <p>6.2.1 promptly notify Customer if Crazy Ant Labs receives a request from a Customer's Data Subject under any Data Protection Law in respect of EU Customer Personal Data; and</p>
            <p>6.2.2 ensure that Crazy Ant Labs does not respond to that request except to acknowledge to the requestor that it has been received and forwarded to Customer as the relevant data controller; additional response shall only be on the documented instructions of Customer or the relevant Customer Affiliate or as required by Applicable Laws to which the Crazy Ant Labs is subject, in which case Crazy Ant Labs shall, to the extent permitted by Applicable Laws, inform Customer of that legal requirement before Crazy Ant Labs responds to the request.</p>
            <h4><b>7. Personal Data Breach</b></h4>
            <p>7.1 Crazy Ant Labs shall notify Customer without undue delay upon Crazy Ant Labs or any Subprocessor becoming aware of a Personal Data Breach affecting EU Customer Personal Data, providing Customer with sufficient information to allow each Customer or its Affiliates to meet any obligations to report or inform Data Subjects of the Personal Data Breach under the EU Data Protection Laws.</p>
            <p>7.2 Crazy Ant Labs shall cooperate with Customer and each Customer or its Affiliates and take such reasonable commercial steps as are directed by Customer to assist in the investigation, mitigation and remediation of each such Personal Data Breach.</p>
            <p>7.3 Nothing in this section 7 alters the parties’ financial responsibilities for the indemnities set forth in the Terms of Service; in particular Customer may be liable for Crazy Ant Labs expenses in mitigating a Personal Data Breach to the extent such breach was caused by an action or inaction by Customer.</p>
            <h4><b>8. Data Protection Impact Assessment and Prior Consultation</b></h4>
            <p>Crazy Ant Labs shall provide reasonable assistance to each Customer or its Affiliates with any data protection impact assessments, and prior consultations with Supervising Authorities or other competent data privacy authorities, which Customer reasonably considers to be required of any Customer or its Affiliates by article 35 or 36 of the GDPR or equivalent provisions of any other Data Protection Law, in each case solely in relation to Processing of EU Customer Personal Data by, and taking into account the nature of the Processing and information available to Crazy Ant Labs. Crazy Ant Labs may charge Customer a commercially reasonable fee for such assistance.</p>
            <h4><b>9. Deletion or return of EU Customer Personal Data</b></h4>
            <p>9.1 The date of the termination of the Customer Account pursuant to Section “Termination” of the Terms of Service is the “<b>Cessation Date</b>”. Subject to sections 9.2 and 9.3, Crazy Ant Labs shall promptly and in any event within thirty (30) days after the Cessation Date, delete and procure the deletion of all copies of those EU Customer Personal Data.</p>
            <p>9.2 Prior to the Cessation Date, Customer may use the Crazy Ant Labs user interface to download all EU Customer Personal Data to Customer by secure file transfer.</p>
            <p>9.3 Crazy Ant Labs may retain EU Customer Personal Data to the extent required by Applicable Laws, and always provided that Crazy Ant Labs shall ensure the confidentiality of all such EU Customer Personal Data, and shall ensure that such EU Customer Personal Data is only Processed as necessary for the purpose(s) specified in the Applicable Laws requiring its storage and for no other purpose. Crazy Ant Labs may also retain information about the Customer, such as customer name, contact details, and purchase history, for purposes of communications that Customer has consented to, where such consent has not been withdrawn (e.g., direct marketing email list).</p>
            <h4><b>10. Audit rights</b></h4>
            <p>10.1 Subject to the limitations of sections 10.2 to 10.3, Crazy Ant Labs shall make available to each Customer or its Affiliates on request all information necessary to demonstrate compliance with this Addendum, and shall allow for and contribute to audits, including inspections, by any Customer or its Affiliates or an auditor mandated by any Customer or its Affiliates in relation to the Processing of the EU Customer Personal Data by Crazy Ant Labs.</p>
            <p>10.2 Information and audit rights of the Customer or its Affiliates only arise under section 10.1 to the extent that the Terms of Service do not otherwise give them information and audit rights meeting the relevant requirements of Data Protection Law (including, where applicable, article 28(3)(h) of the GDPR).</p>
            <p>10.3 Following receipt by Crazy Ant Labs of a request from Customer for an audit or inspection pursuant to section 10.1, Crazy Ant Labs and Customer will discuss and agree in advance on its reasonable start date, scope and duration of and security and confidentiality controls applicable to the audit/inspection.</p>
            <p>10.4 Crazy Ant Labs shall have the right to refuse access to any auditor, where Crazy Ant Labs can specify a reasonable basis, such as affiliation with an Crazy Ant Labs competitor or lack of proper qualification.</p>
            <p>10.5 Customer shall pay the fees and expenses of the auditor/inspector. Crazy Ant Labs may charge Customer a reasonable fee for Crazy Ant Labs cooperation with the audit/inspection. Crazy Ant Labs may require that the auditor sign any reasonable legal documentation to ensure the confidentiality of Crazy Ant Labs trade secrets and confidential information.</p>
            <p>10.6 Crazy Ant Labs shall not be required to submit to more than one audit in any given calendar year, except for any additional audits or inspections which:</p>
            <p>10.6.1 Customer or the relevant Customer Affiliate undertaking an audit reasonably and in good faith considers necessary because of genuine concerns as to Crazy Ant Labs’ compliance with this Addendum; or</p>
            <p>10.6.2 A Customer or its Affiliates are required or requested to carry out by Data Protection Law, a Supervisory Authority or any similar regulatory authority responsible for the enforcement of EU Data Protection Laws in any country or territory.</p>
            <h4><b>11. Restricted Transfers</b></h4>
            <p>11.1 Subject to section 11.3, each Customer or its Affiliates (as “data exporter”) and Crazy Ant Labs, as appropriate, (as “data importer”) hereby enter into the Standard Contractual Clauses in respect of any Restricted Transfer from that Customer or its Affiliates to that Crazy Ant Labs.</p>
            <p>11.2 The Standard Contractual Clauses shall come into effect under section 11.1 on the later of:</p>
            <p>11.2.1 the data exporter becoming a party to them;</p>
            <p>11.2.2 the data importer becoming a party to them; and</p>
            <p>11.2.3 commencement of the relevant Restricted Transfer.</p>
            <p>11.3 Section 11.1 shall not apply to a Restricted Transfer unless its effect, together with other reasonably practicable compliance steps (which, for the avoidance of doubt, do not include obtaining consents from Data Subjects), is to allow the relevant Restricted Transfer to take place without breach of applicable Data Protection Law. For the avoidance of doubt, the parties anticipate that the US-EU Privacy Shield program will provide adequate safeguards and transfers to the United States will not be Restricted Transfer.</p>
            <p>11.4 <i>Transfers outside the US and EU</i>. The parties agree that Crazy Ant Labs may use personnel or servers not located in the United States or the European Union. In the event that this is the case, the parties anticipate that the Standard Contractual Clauses will be the mechanism that prevents such Restricted Transfer to occur in compliance with Data Protection Law. In all cases Crazy Ant Labs will ensure that such personnel are subject to appropriate confidentiality requirements, including standard contractual clauses similar to those in Annex 2 to this addendum, including any necessary changes to reflect changes in the law.</p>
            <h4><b>12. General Terms</b></h4>
            <p>12.1 <i>Governing law and jurisdiction</i>. Without prejudice to clauses 7 (Mediation and Jurisdiction) and 9 (Governing Law) of the Standard Contractual Clauses:</p>
            <p>12.1.1 the parties to this Addendum hereby submit to the choice of jurisdiction stipulated in the Terms of Use with respect to any disputes or claims howsoever arising under this Addendum, including disputes regarding its existence, validity or termination or the consequences of its nullity; and</p>
            <p>12.1.2 this Addendum and all non-contractual or other obligations arising out of or in connection with it are governed by the laws of the country or territory stipulated for this purpose in the Terms of Use.</p>
            <p>12.1.3 If an obligation under the Standard Contractual Clauses, or other legal obligation, requires that the parties submit to a jurisdiction and/or use the laws of a nation within the EU, the parties choose the courts and law of Ireland.</p>
            <p>12.2 <i>Priority of Documents</i>. Nothing in this Addendum reduces Crazy Ant Labs’s obligations under the Terms of Use in relation to the protection of Personal Data or permits Crazy Ant Labs to Process (or permit the Processing of) Personal Data in a manner which is prohibited by the Terms of Use. In the event of any conflict or inconsistency between this Addendum and the Standard Contractual Clauses, the Standard Contractual Clauses shall prevail. Subject to section 12.2, with regard to the subject matter of this Addendum, in the event of inconsistencies between the provisions of this Addendum and any other agreements between the parties, including the Terms of Use and including (except where explicitly agreed otherwise in writing, signed on behalf of the parties) agreements entered into or purported to be entered into after the date of this Addendum, the provisions of this Addendum shall prevail.</p>
            <p>12.3 <i>Good Faith Negotiations To Revise, If Required By Law</i>. If any variations to this Addendum, (including any Standard Contractual Clauses entered into under section 11.1), as it applies to Restricted Transfers which are subject to a particular Data Protection Law, are required as a result of any change in, or decision of a competent authority under, that Data Protection Law the Parties agree to review each other’s proposed variations and negotiate in good faith with a view to agreeing and implementing those or alternative variations designed to address the requirements identified for compliance with the Data Protection Law.</p>
            <p>12.4 <i>Severance</i>. Should any provision of this Addendum be invalid or unenforceable, then the remainder of this Addendum shall remain valid and in force. The invalid or unenforceable provision shall be either (i) amended as necessary to ensure its validity and enforceability, while preserving the parties’ intentions as closely as possible or, if this is not possible, (ii) construed in a manner as if the invalid or unenforceable part had never been contained therein.</p>
            <p>12.5 <i>Assumption of Obligations</i>. Either party may transfer and assign its benefits and obligations pursuant to this Addendum to a successor entity in a merger or acquisition, or to a third party in a sale of all or substantially all of the Party’s assets.</p>
            <h2>ANNEX 1: DETAILS OF PROCESSING OF COMPANY PERSONAL DATA</h2>
            <p>This Annex 1 includes certain details of the Processing of EU Customer Personal Data as required by Article 28(3) GDPR.</p>
            <p><i>Subject matter and duration of the Processing of EU Customer Personal Data</i></p>
            <p>The subject matter and duration of the Processing of the EU Customer Personal Data are set out in the Terms of Use and this Addendum.</p>
            <p><i>The nature and purpose of the Processing of EU Customer Personal Data</i></p>
            <p>Crazy Ant Labs provides data storage and file transfer services. Crazy Ant Labs does not access, control, or review the data uploaded by Customer.</p>
            <p><i>The types of EU Customer Personal Data to be Processed</i></p>
            <p>Crazy Ant Labs will store the data that is uploaded by Customer to the Service. Crazy Ant Labs will not access (except in response to a Customer request, e.g. a support request) or control the data uploaded.</p>
            <p><i>The categories of Data Subject to whom the EU Customer Personal Data relates</i></p>
            <p>Data relating to individuals provided to Crazy Ant Labs via the Services, by (or at the direction of) Customer or by Customer’s end users.</p>
            <p><i>The obligations and rights of Customer and Customer Affiliates</i></p>
            <p>The obligations and rights of Customer and Customer Affiliates are set out in the Terms of Use and this Addendum.</p>
            <h2>ANNEX 2: STANDARD CONTRACTUAL CLAUSES</h2>
            <p><i>These Clauses are deemed to be amended from time to time, to the extent that they relate to a Restricted Transfer which is subject to the EU Data Protection Laws of a given country or territory, to reflect (to the extent possible without material uncertainty as to the result) any change (including any replacement) made in accordance with those EU Data Protection Laws (i) by the Commission to or of the equivalent contractual clauses approved by the Commission under EU Directive 95/46/EC or the GDPR (in the case of the EU Data Protection Laws of the European Union or a Member State); or (ii) by an equivalent competent authority to or of any equivalent contractual clauses approved by it or by another competent authority under another Data Protection Law (otherwise)</i></p>
            <h4><b>Standard Contractual Clauses (processors)</b></h4>
            <p>For the purposes of Article 26(2) of Directive 95/46/EC for the transfer of personal data to processors established in third countries which do not ensure an adequate level of data protection</p>
            <p>Name of the data exporting organisation: Customer, with customer details as provided at the time of signup for the Service.</p>
            <p>Name of the data importing organisation: Crazy Ant Labs Ltd.,<br/>
            Address: 14 Toledano St., Tel Aviv, Israel<br/>
            e-mail: support@crazyantlabs.com<br/>
            …………………………………………………………………<br/>
            (the data <b>importer</b>)<br/>
            each a “party”; together “the parties”,</p>
            <p>HAVE AGREED on the following Contractual Clauses (the Clauses) in order to adduce adequate safeguards with respect to the protection of privacy and fundamental rights and freedoms of individuals for the transfer by the data exporter to the data importer of the personal data specified in Appendix 1.</p>
            <p>Background</p>
            <p>The data exporter has entered into a data processing addendum (“DPA”) with the data importer. Pursuant to the terms of the DPA, it is contemplated that services provided by the data importer will involve the transfer of personal data to data importer. Data importer is located in a country not ensuring an adequate level of data protection. To ensure compliance with Directive 95/46/EC and applicable data protection law, the controller agrees to the provision of such Services, including the processing of personal data incidental thereto, subject to the data importer’s execution of, and compliance with, the terms of these Clauses.</p>
            <h4><b>Clause 1</b></h4>
            <p><b>Definitions</b></p>
            <p>For the purposes of the Clauses:</p>
            <p>(a) ‘<i>personal data</i>’, ‘<i>special categories of data</i>’, ‘<i>process/processing</i>’, ‘<i>controller</i>’, ‘<i>processor</i>’, ‘<i>data subject</i>’ and ‘<i>supervisory authority</i>’ shall have the same meaning as in Directive 95/46/EC of the European Parliament and of the Council of 24 October 1995 on the protection of individuals with regard to the processing of personal data and on the free movement of such data;</p>
            <p>(b) ‘<i>the data exporter</i>’ means the controller who transfers the personal data;</p>
            <p>(c) ‘<i>the data importer</i>’ means the processor who agrees to receive from the data exporter personal data intended for processing on his behalf after the transfer in accordance with his instructions and the terms of the Clauses and who is not subject to a third country’s system ensuring adequate protection within the meaning of Article 25(1) of Directive 95/46/EC;</p>
            <p>(d) ‘<i>the subprocessor</i>’ means any processor engaged by the data importer or by any other subprocessor of the data importer who agrees to receive from the data importer or from any other subprocessor of the data importer personal data exclusively intended for processing activities to be carried out on behalf of the data exporter after the transfer in accordance with his instructions, the terms of the Clauses and the terms of the written subcontract;</p>
            <p>(e) ‘<i>the applicable data protection law</i>’ means the legislation protecting the fundamental rights and freedoms of individuals and, in particular, their right to privacy with respect to the processing of personal data applicable to a data controller in the Member State in which the data exporter is established;</p>
            <p>(f) ‘<i>technical and organisational security measures</i>’ means those measures aimed at protecting personal data against accidental or unlawful destruction or accidental loss, alteration, unauthorised disclosure or access, in particular where the processing involves the transmission of data over a network, and against all other unlawful forms of processing.</p>
            <h4><b>Clause 2</b></h4>
            <p><b>Details of the transfer</b></p>
            <p>The details of the transfer and in particular the special categories of personal data where applicable are specified in Appendix 1 which forms an integral part of the Clauses.</p>
            <h4><b>Clause 3</b></h4>
            <p><b>Third-party beneficiary clause</b></p>
            <p>1. The data subject can enforce against the data exporter this Clause, Clause 4(b) to (i), Clause 5(a) to (e), and (g) to (j), Clause 6(1) and (2), Clause 7, Clause 8(2), and Clauses 9 to 12 as third-party beneficiary.</p>
            <p>2. The data subject can enforce against the data importer this Clause, Clause 5(a) to (e) and (g), Clause 6, Clause 7, Clause 8(2), and Clauses 9 to 12, in cases where the data exporter has factually disappeared or has ceased to exist in law unless any successor entity has assumed the entire legal obligations of the data exporter by contract or by operation of law, as a result of which it takes on the rights and obligations of the data exporter, in which case the data subject can enforce them against such entity.</p>
            <p>3. The data subject can enforce against the subprocessor this Clause, Clause 5(a) to (e) and (g), Clause 6, Clause 7, Clause 8(2), and Clauses 9 to 12, in cases where both the data exporter and the data importer have factually disappeared or ceased to exist in law or have become insolvent, unless any successor entity has assumed the entire legal obligations of the data exporter by contract or by operation of law as a result of which it takes on the rights and obligations of the data exporter, in which case the data subject can enforce them against such entity. Such third-party liability of the subprocessor shall be limited to its own processing operations under the Clauses.</p>
            <p>4. The parties do not object to a data subject being represented by an association or other body if the data subject so expressly wishes and if permitted by national law.</p>
            <h4><b>Clause 4</b></h4>
            <p><b>Obligations of the data exporter</b></p>
            <p>The data exporter agrees and warrants:</p>
            <p>(a) that the processing, including the transfer itself, of the personal data has been and will continue to be carried out in accordance with the relevant provisions of the applicable data protection law (and, where applicable, has been notified to the relevant authorities of the Member State where the data exporter is established) and does not violate the relevant provisions of that State;</p>
            <p>(b) that it has instructed and throughout the duration of the personal data processing services will instruct the data importer to process the personal data transferred only on the data exporter’s behalf and in accordance with the applicable data protection law and the Clauses;</p>
            <p>(c) that the data importer will provide sufficient guarantees in respect of the technical and organisational security measures specified in Appendix 2 to this contract;</p>
            <p>(d) that after assessment of the requirements of the applicable data protection law, the security measures are appropriate to protect personal data against accidental or unlawful destruction or accidental loss, alteration, unauthorised disclosure or access, in particular where the processing involves the transmission of data over a network, and against all other unlawful forms of processing, and that these measures ensure a level of security appropriate to the risks presented by the processing and the nature of the data to be protected having regard to the state of the art and the cost of their implementation;</p>
            <p>(e) that it will ensure compliance with the security measures;</p>
            <p>(f) that, if the transfer involves special categories of data, the data subject has been informed or will be informed before, or as soon as possible after, the transfer that its data could be transmitted to a third country not providing adequate protection within the meaning of Directive 95/46/EC;</p>
            <p>(g) to forward any notification received from the data importer or any subprocessor pursuant to Clause 5(b) and Clause 8(3) to the data protection supervisory authority if the data exporter decides to continue the transfer or to lift the suspension;</p>
            <p>(h) to make available to the data subjects upon request a copy of the Clauses, with the exception of Appendix 2, and a summary description of the security measures, as well as a copy of any contract for subprocessing services which has to be made in accordance with the Clauses, unless the Clauses or the contract contain commercial information, in which case it may remove such commercial information;</p>
            <p>(i) that, in the event of subprocessing, the processing activity is carried out in accordance with Clause 11 by a subprocessor providing at least the same level of protection for the personal data and the rights of data subject as the data importer under the Clauses; and</p>
            <p>(j) that it will ensure compliance with Clause 4(a) to (i).</p>
            <h4><b>Clause 5</b></h4>
            <p><b>Obligations of the data importer</b></p>
            <p>The data importer agrees and warrants:</p>
            <p>(a) to process the personal data only on behalf of the data exporter and in compliance with its instructions and the Clauses; if it cannot provide such compliance for whatever reasons, it agrees to inform promptly the data exporter of its inability to comply, in which case the data exporter is entitled to suspend the transfer of data and/or terminate the contract;</p>
            <p>(b) that it has no reason to believe that the legislation applicable to it prevents it from fulfilling the instructions received from the data exporter and its obligations under the contract and that in the event of a change in this legislation which is likely to have a substantial adverse effect on the warranties and obligations provided by the Clauses, it will promptly notify the change to the data exporter as soon as it is aware, in which case the data exporter is entitled to suspend the transfer of data and/or terminate the contract;</p>
            <p>(c) that it has implemented the technical and organisational security measures specified in Appendix 2 before processing the personal data transferred;</p>
            <p>(d) that it will promptly notify the data exporter about:</p>
            <p>(i) any legally binding request for disclosure of the personal data by a law enforcement authority unless otherwise prohibited, such as a prohibition under criminal law to preserve the confidentiality of a law enforcement investigation,</p>
            <p>(ii) any accidental or unauthorised access, and</p>
            <p>(iii) any request received directly from the data subjects without responding to that request, unless it has been otherwise authorised to do so;</p>
            <p>(e) to deal promptly and properly with all inquiries from the data exporter relating to its processing of the personal data subject to the transfer and to abide by the advice of the supervisory authority with regard to the processing of the data transferred;</p>
            <p>(f) at the request of the data exporter to submit its data processing facilities for audit of the processing activities covered by the Clauses which shall be carried out by the data exporter or an inspection body composed of independent members and in possession of the required professional qualifications bound by a duty of confidentiality, selected by the data exporter, where applicable, in agreement with the supervisory authority;</p>
            <p>(g) to make available to the data subject upon request a copy of the Clauses, or any existing contract for subprocessing, unless the Clauses or contract contain commercial information, in which case it may remove such commercial information, with the exception of Appendix 2 which shall be replaced by a summary description of the security measures in those cases where the data subject is unable to obtain a copy from the data exporter;</p>
            <p>(h) that, in the event of subprocessing, it has previously informed the data exporter and obtained its prior written consent;</p>
            <p>(i) that the processing services by the subprocessor will be carried out in accordance with Clause 11;</p>
            <p>(j) to send promptly a copy of any subprocessor agreement it concludes under the Clauses to the data exporter.</p>
            <h4><b>Clause 6</b></h4>
            <p><b>Liability</b></p>
            <p>1. The parties agree that any data subject, who has suffered damage as a result of any breach of the obligations referred to in Clause 3 or in Clause 11 by any party or subprocessor is entitled to receive compensation from the data exporter for the damage suffered.</p>
            <p>2. If a data subject is not able to bring a claim for compensation in accordance with paragraph 1 against the data exporter, arising out of a breach by the data importer or his subprocessor of any of their obligations referred to in Clause 3 or in Clause 11, because the data exporter has factually disappeared or ceased to exist in law or has become insolvent, the data importer agrees that the data subject may issue a claim against the data importer as if it were the data exporter, unless any successor entity has assumed the entire legal obligations of the data exporter by contract of by operation of law, in which case the data subject can enforce its rights against such entity.</p>
            <p>The data importer may not rely on a breach by a subprocessor of its obligations in order to avoid its own liabilities.</p>
            <p>3. If a data subject is not able to bring a claim against the data exporter or the data importer referred to in paragraphs 1 and 2, arising out of a breach by the subprocessor of any of their obligations referred to in Clause 3 or in Clause 11 because both the data exporter and the data importer have factually disappeared or ceased to exist in law or have become insolvent, the subprocessor agrees that the data subject may issue a claim against the data subprocessor with regard to its own processing operations under the Clauses as if it were the data exporter or the data importer, unless any successor entity has assumed the entire legal obligations of the data exporter or data importer by contract or by operation of law, in which case the data subject can enforce its rights against such entity. The liability of the subprocessor shall be limited to its own processing operations under the Clauses.</p>
            <h4><b>Clause 7</b></h4>
            <p><b>Mediation and jurisdiction</b></p>
            <p>1. The data importer agrees that if the data subject invokes against it third-party beneficiary rights and/or claims compensation for damages under the Clauses, the data importer will accept the decision of the data subject:</p>
            <p>(a) to refer the dispute to mediation, by an independent person or, where applicable, by the supervisory authority;</p>
            <p>(b) to refer the dispute to the courts in the Member State in which the data exporter is established.</p>
            <p>2. The parties agree that the choice made by the data subject will not prejudice its substantive or procedural rights to seek remedies in accordance with other provisions of national or international law.</p>
            <h4><b>Clause 8</b></h4>
            <p><b>Cooperation with supervisory authorities</b></p>
            <p>1. The data exporter agrees to deposit a copy of this contract with the supervisory authority if it so requests or if such deposit is required under the applicable data protection law.</p>
            <p>2. The parties agree that the supervisory authority has the right to conduct an audit of the data importer, and of any subprocessor, which has the same scope and is subject to the same conditions as would apply to an audit of the data exporter under the applicable data protection law.</p>
            <p>3. The data importer shall promptly inform the data exporter about the existence of legislation applicable to it or any subprocessor preventing the conduct of an audit of the data importer, or any subprocessor, pursuant to paragraph 2. In such a case the data exporter shall be entitled to take the measures foreseen in Clause 5 (b).</p>
            <h4><b>Clause 9</b></h4>
            <p><b>Governing Law</b></p>
            <p>The Clauses shall be governed by the law of the Member State in which the data exporter is established.</p>
            <h4><b>Clause 10</b></h4>
            <p><b>Variation of the contract</b></p>
            <p>The parties undertake not to vary or modify the Clauses. This does not preclude the parties from adding clauses on business related issues where required as long as they do not contradict the Clause.</p>
            <h4><b>Clause 11</b></h4>
            <p><b>Subprocessing</b></p>
            <p>1. The data importer shall not subcontract any of its processing operations performed on behalf of the data exporter under the Clauses without the prior written consent of the data exporter. Where the data importer subcontracts its obligations under the Clauses, with the consent of the data exporter, it shall do so only by way of a written agreement with the subprocessor which imposes the same obligations on the subprocessor as are imposed on the data importer under the Clauses. Where the subprocessor fails to fulfil its data protection obligations under such written agreement the data importer shall remain fully liable to the data exporter for the performance of the subprocessor’s obligations under such agreement.</p>
            <p>2. The prior written contract between the data importer and the subprocessor shall also provide for a third-party beneficiary clause as laid down in Clause 3 for cases where the data subject is not able to bring the claim for compensation referred to in paragraph 1 of Clause 6 against the data exporter or the data importer because they have factually disappeared or have ceased to exist in law or have become insolvent and no successor entity has assumed the entire legal obligations of the data exporter or data importer by contract or by operation of law. Such third-party liability of the subprocessor shall be limited to its own processing operations under the Clauses.</p>
            <p>3. The provisions relating to data protection aspects for subprocessing of the contract referred to in paragraph 1 shall be governed by the law of the Member State in which the data exporter is established.</p>
            <p>4. The data exporter shall keep a list of subprocessing agreements concluded under the Clauses and notified by the data importer pursuant to Clause 5 (j), which shall be updated at least once a year. The list shall be available to the data exporter’s data protection supervisory authority.</p>
            <h4><b>Clause 12</b></h4>
            <p><b>Obligation after the termination of personal data processing services</b></p>
            <p>1. The parties agree that on the termination of the provision of data processing services, the data importer and the subprocessor shall, at the choice of the data exporter, return all the personal data transferred and the copies thereof to the data exporter or shall destroy all the personal data and certify to the data exporter that it has done so, unless legislation imposed upon the data importer prevents it from returning or destroying all or part of the personal data transferred. In that case, the data importer warrants that it will guarantee the confidentiality of the personal data transferred and will not actively process the personal data transferred anymore.</p>
            <p>2. The data importer and the subprocessor warrant that upon request of the data exporter and/or of the supervisory authority, it will submit its data processing facilities for an audit of the measures referred to in paragraph 1.</p>
            <h2>APPENDIX 1 TO THE STANDARD CONTRACTUAL CLAUSES</h2>
            <p>This Appendix forms part of the Clauses and must be completed and signed by the parties The Member States may complete or specify, according to their national procedures, any additional necessary information to be contained in this Appendix</p>
            <h4><b>Data exporter</b></h4>
            <p>The data exporter is:<br/>
            <br/>
            The “Customer” specified in the Terms of Use and the Data Processing Addendum.</p>
            <h4><b>Data importer</b></h4>
            <p>The data importer is:<br/>
            <br/>
            Crazy Ant Labs.</p>
            <h4><b>Data subjects</b></h4>
            <p>The personal data transferred concern the following categories of data subjects:<br/>
            Data subjects include the individuals about whom data is provided to Crazy Ant Labs via the Service.</p>
            <h4><b>Categories of data</b></h4>
            <p>The personal data transferred concern the following categories of data:<br/>
            <br/>
            Data uploaded by Customer to the Crazy Ant Labs service.</p>
            <h4><b>Special categories of data (if appropriate)</b></h4>
            <p>The personal data transferred concern the following special categories of data:<br/>
            <br/>
            Customer’s data.</p>
            <h4><b>Processing operations</b></h4>
            <p>The personal data transferred will be subject to the following basic processing activities:<br/>
            <br/>
            Data storage and transfer. Crazy Ant Labs does not access, control, or review the data uploaded by Customer</p>
            <h2>APPENDIX 2 TO THE STANDARD CONTRACTUAL CLAUSES</h2>
            <p>This Appendix forms part of the Clauses and must be completed and signed by the parties.</p>
            <p>The technical and organisational security measures implemented by the data importer in accordance with Clauses 4(d) and 5(c) are located at <a href="https://mailertogo.com/security">https://mailertogo.com/security</a></p>
          </div>
        </div>
      </div>
    );
  }
}
