import React, { Component } from "react";
import SEO from '../../components/SEO';

import { page } from '../../services/analytics.js'

import ProfileCard from './ProfileCard';
import LoginsCard from './LoginsCard';
import OrganizationsCard from './OrganizationsCard';

import './AccountSettings.css'

export default class AccountSettings extends Component {

  async componentDidMount() {
    page();
  }

  render() {
    return (
      <div className="AccountSettings">
        <SEO title="Account Settings" />
        <div className="container mt-4 mb-4">
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <div className="d-flex mb-3">
                <div className="flex-grow-1 align-self-center">
                  <h4>Account Settings</h4>
                  <div className="text-muted mr-1">
                    Manage your personal settings
                  </div>
                </div>
              </div>
              <ProfileCard className="mb-3" />
              <LoginsCard className="mb-3" limit={5} />
              <OrganizationsCard className="mb-3" limit={5} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
