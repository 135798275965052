import React, { Component } from "react";
import { Link } from "react-router-dom";
import { CircleSlashIcon } from '@primer/octicons-react';
import { page } from '../services/analytics.js'
import "./Unauthorized.css";

export default class Unauthorized extends Component {
  
  componentDidMount() {
    page();
  }

  render() {
    return (
      <div className="bg-white py-5">
        <div className="text-center py-5">
          <CircleSlashIcon size={60} className="text-danger mb-4" />
          <h5>Sorry, you are not authorized to access this page.</h5>
          <p>Perhaps head back <Link to="/">home</Link>?</p>
        </div>
      </div>
    );
  }
}