import React, { useContext, useState } from 'react';

import './NotificationBar.css';

export const NotificationContext = React.createContext({
  notificationComponent: null,
  setNotificationComponent(){},
});

export function Notifications(props) {
  const [notificationComponent, setNotificationComponent] = useState(null);
  return <NotificationContext.Provider value={{ notificationComponent, setNotificationComponent }}>{props.children}</NotificationContext.Provider>
}

export function NotificationBar() {
  const { notificationComponent } = useContext(NotificationContext);
  return <div className="NotificationBar">{notificationComponent}</div>
}

export function withNotifications(Component) {
  return function(props) {
    return <NotificationContext.Consumer>
      {(context) => (
        <Component
          {...props}
          setNotificationComponent={context.setNotificationComponent}
          notificationComponent={context.notificationComponent}
        />
      )}
    </NotificationContext.Consumer>
  };
}
