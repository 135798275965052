import React, { useContext, useCallback } from 'react';

import { NotificationContext } from './NotificationBar';

export function NotificationMessage({ className, children }) {
  const { setNotificationComponent } = useContext(NotificationContext);

  const closeNotification = useCallback(() => {
    setNotificationComponent(null);
  }, [setNotificationComponent]);

  className = "notification " + className;

  return (
    <>
      <div className={className}>{children}</div>
      <button className="close-button" onClick={closeNotification}>
        &#x2715;
      </button>
    </>
  );
}
