import React, { Component } from "react";
import {
  Card,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  CustomInput
} from "reactstrap";
import "./KeyboardShortcutsDialog.css";

export default class KeyboardShortcutsDialog extends Component {
  constructor(props) {
    super(props);
    this.toggleEnabled = this.toggleEnabled.bind(this);
    this.handleClosed = this.handleClosed.bind(this);
    this.handleLocalStorageChange = this.handleLocalStorageChange.bind(this);
    this.state = {
      enabled: (localStorage.getItem("keyboard_shortcuts") !== "false")
    };
  }

  componentDidMount() {
    window.addEventListener('storage', this.handleLocalStorageChange);
  }

  componentWillUnmount() {
    window.removeEventListener('storage', this.handleLocalStorageChange);
  }

  handleLocalStorageChange = (e) => {
    this.setState({
      enabled: localStorage.getItem("keyboard_shortcuts") !== "false"
    })
  }

  toggleEnabled = (e) =>{
    this.setState({enabled : e.target.checked});
    localStorage.setItem("keyboard_shortcuts", e.target.checked ? true : false)
  }

  handleClosed = () => {
    localStorage.setItem("keyboard_shortcuts", this.state.enabled);
  }

  modKey = () => {
    if( navigator ) {
      return /Mac|iPod|iPhone|iPad/.test(navigator.platform) ? '⌘' : 'ctrl';
    }
    return 'ctrl';
  }  

  render() {
    return (
        <Modal
          autoFocus={true}
          isOpen={this.props.isModalOpen}
          toggle={this.props.toggleModal}
          size="lg"
          onClosed={this.handleClosed} 
        >
          <ModalHeader toggle={this.props.toggleModal}>
            Keyboard shortcuts
          </ModalHeader>
          <ModalBody>
            <CustomInput
              type="switch"
              label="Enable keyboard shortcuts"
              className={this.state.enabled ? "mb-3 text-primary" : " mb-3 text-muted"}
              name="toggle-keyboard-shortcuts"
              id="toggle-keyboard-shortcuts"
              onChange={this.toggleEnabled}
              checked={this.state.enabled}
            />
            <div className="card-deck">
              <Card>
                <CardHeader className="font-weight-bold">Application</CardHeader>
                  <ul className="list-group list-group-flush">
                    {  window.Intercom && <li className="list-group-item d-flex align-items-center">
                      <span className="mr-auto">Contact support</span>
                      <kbd>s</kbd>
                    </li> }
                    <li className="list-group-item d-flex align-items-center">
                      <span className="mr-auto">Display keyboard shortcuts</span>
                      <kbd>?</kbd>
                    </li>
                  </ul>
              </Card>
              <Card>
                <CardHeader className="font-weight-bold">Create</CardHeader>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item d-flex align-items-center">
                    <span className="mr-auto">New domain</span>
                    <kbd>c</kbd><small className="text-muted mx-1">then</small><kbd>d</kbd>
                  </li>
                </ul>
              </Card>
              <div className="w-100 mb-3 d-none d-sm-block"></div>
              <Card>
                <CardHeader className="font-weight-bold">Navigation</CardHeader>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item d-flex align-items-center">
                    <span className="mr-auto">Go to dashboard</span>
                    <kbd>g</kbd><small className="text-muted mx-1">then</small><kbd>d</kbd>
                  </li>
                  <li className="list-group-item d-flex align-items-center">
                    <span className="mr-auto">Go to import domains</span>
                    <kbd>g</kbd><small className="text-muted mx-1">then</small><kbd>i</kbd>
                  </li>
                  <li className="list-group-item d-flex align-items-center">
                    <span className="mr-auto">Switch organization</span>
                    <kbd>=</kbd>
                  </li>
                  <li className="list-group-item d-flex align-items-center">
                    <span className="mr-auto">Close dialog</span>
                    <kbd>esc</kbd>
                  </li>
                </ul>
              </Card>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" outline onClick={this.props.toggleModal}>Close</Button>
          </ModalFooter>
        </Modal>
    );
  }
}
