import React, { Component } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavItem
} from "reactstrap";
import { MoonIcon, SunIcon, DeviceDesktopIcon } from "@primer/octicons-react";

export default class ThemeNavItem extends Component {
  constructor(props) {
    super(props);

    this.getSystemTheme = this.getSystemTheme.bind(this);
    this.setSelectedTheme = this.setSelectedTheme.bind(this);
    this.getSelectedTheme = this.getSelectedTheme.bind(this);
    this.state = {
      isOpen: false,
      selectedTheme: this.getSelectedTheme(),
      systemTheme: this.getSystemTheme()
    };
  }

  componentDidMount() {
    window.matchMedia("(prefers-color-scheme: dark)").addListener(
      e => this.setState({
        systemTheme: e.matches ? 'dark' : 'light'
      }) // listener
    );

    window.addEventListener('storage', (e) => {
      // When local storage changes in other tabs / windows.
      this.setState({
        selectedTheme: this.getSelectedTheme()
      })
    });
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  setSelectedTheme(theme) {
    localStorage.setItem("theme", theme);
    window.dispatchEvent(new Event('themeChanged'));
  }

  getSelectedTheme() {
    return localStorage.getItem("theme") || 'system';
  }

  getSystemTheme() {
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches)  {
      return 'dark';
    }
    return 'light';
  }

  render() {
    var {className} = this.props;

    let systemTheme = this.state.systemTheme;
    let selectedTheme = this.getSelectedTheme();
    let activeTheme = selectedTheme === "system" ? systemTheme : selectedTheme;

    className = "ThemeNavItem " + (className || "");

    return (
      <NavItem>
        <Dropdown
          className={className}
          inNavbar
          isOpen={this.state.isOpen}
          toggle={(event) => {
            event.preventDefault();
            event.stopPropagation();
            this.toggle();
          }}
        >
          <DropdownToggle aria-label={activeTheme} nav>
            {activeTheme === "dark" && (
              <MoonIcon size={20} aria-label="dark" verticalAlign="text-top" className={selectedTheme === 'system' ? 'text-muted' : ''} />
            )}
            {activeTheme === "light" && (
              <SunIcon size={20} aria-label="light" verticalAlign="text-top" className={selectedTheme === 'system' ? 'text-muted' : ''} />
            )}
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem header>Appearance</DropdownItem>
            <DropdownItem onClick={() => this.setSelectedTheme("light")} className={selectedTheme === "light" ? 'text-primary' : ''}>
              <SunIcon size={18} aria-label="light" verticalAlign='text-bottom'  className={selectedTheme === "light" ? 'text-primary mr-2' : 'mr-2'} />Light
            </DropdownItem>
            <DropdownItem onClick={() => this.setSelectedTheme("dark")} className={selectedTheme === "dark" ? 'text-primary' : ''}>
              <MoonIcon size={18} aria-label="dark" verticalAlign='text-bottom' className={selectedTheme === "dark" ? 'text-primary mr-2' : 'mr-2'}  />Dark
            </DropdownItem>
            <DropdownItem onClick={() => this.setSelectedTheme("system")} className={selectedTheme === "system" ? 'text-primary' : ''}>
              <DeviceDesktopIcon size={18} aria-label="system" verticalAlign='text-bottom' className={selectedTheme === "system" ? 'text-primary mr-2' : 'mr-2'}  />System
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </NavItem>
    );
  }
}
