import React, { Component } from "react";
import { NavItem, NavLink } from "reactstrap";

export default class LoginNavItem extends Component {
  render() {
    const { text } = this.props;

    return (
      <NavItem>
        <NavLink href={ process.env.REACT_APP_LOGIN_URL }>
          { text }
        </NavLink>
      </NavItem>
    );
  }
}
