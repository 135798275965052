import React, { Component } from "react";
import { page } from '../services/analytics.js'
import "./Terms.css";
import SEO from '../components/SEO';

export default class Terms extends Component {

  componentDidMount() {
    page();
  }

  render() {
    return (
      <div className="Terms mb-4 container-fluid">
      	<SEO title="Terms of Service" description="Crazy Ant Labs terms of service" />
        <div className="row">
          <div className="mt-5 mb-5 col-10 col-md-10 col-xl-10 offset-1 offset-md-1 offset-xl-1 text-justify">
						<h1>Terms of Service</h1>
            <p><small className="text-muted">Effective as of May 1st, 2021</small></p>

						<h3>Overview</h3>

						<p>
						This website is operated by Crazy Ant Labs. Throughout the site, the terms “we”, “us” and “our” refer to Crazy Ant Labs. Crazy Ant Labs offers this website, including all information, tools and services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.
						</p><p>
						By visiting our site and/or purchasing anything from us, you engage in our “Services” and agree to be bound by the following terms and conditions (“Terms of Service”, “Terms”), including those additional terms and conditions and policies referenced herein and/or available by hyperlink. These Terms of Service apply to all users of the site, including without limitation users who are browsers, vendors, customers, merchants, and/or contributors of content.
						</p><p>
						Please read these Terms of Service carefully before accessing or using our website. By accessing or using any part of the site, you agree to be bound by these Terms of Service. If you do not agree to all the terms and conditions of this agreement, then you may not access the website or use any services. If these Terms of Service are considered an offer, acceptance is expressly limited to these Terms of Service.
						</p><p>
						Any new features or tools which are added to the current services shall also be subject to the Terms of Service. You can review the most current version of the Terms of Service at any time on this page. We reserve the right to update, change or replace any part of these Terms of Service by posting updates and/or changes to our website. It is your responsibility to check this page periodically for changes. Your continued use of or access to the website following the posting of any changes constitutes acceptance of those changes.
						</p><p>
						If a separate written agreement about the services already exists between you and us, the terms of that written agreement (excluding the pre-printed terms of any purchase order, confirmation or similar document, which will have no effect and will not be considered agreed to by us) will take precedence over this agreement, and you acknowledge that you are bound by the terms of that written services agreement rather than these terms.
						</p>

						<h3>General Conditions</h3>

						<p>
						By agreeing to these Terms of Service, You represent that you are at least the age of majority in Your jurisdiction.
						</p><p>
						You may not use our products for any illegal or unauthorized purpose nor may you, in the use of the Service, violate any laws in Your jurisdiction (including but not limited to copyright laws).
						</p><p>
						You must not transmit any executable files or any code of a destructive nature.
						</p><p>
						A breach or violation of any of the Terms will result in an immediate termination of your Services.
						</p><p>
						We reserve the right to refuse service to anyone for any reason at any time.
						</p><p>
						You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the Service or any contact on the website through which the service is provided, without express written permission by us.
						</p><p>
						The headings used in this agreement are included for convenience only and will not limit or otherwise affect these Terms.
						</p>

						<h3>Errors, Inaccuracies And Omissions</h3>
						<p>
						We are not responsible if information made available on this site is not accurate, complete or current. The material on this site is provided for general information only and should not be relied upon or used as the sole basis for making decisions without consulting primary, more accurate, more complete or more timely sources of information. Any reliance on the material on this site is at your own risk.
						</p><p>
						This site may contain certain historical information. Historical information, necessarily, is not current and is provided for your reference only. We reserve the right to modify the contents of this site at any time, but we have no obligation to update any information on our site. You agree that it is your responsibility to monitor changes to our site.
						</p><p>
						Occasionally there may be information on our site or in the Service that contains typographical errors, inaccuracies or omissions that may relate to product descriptions, pricing, promotions, offers, product shipping charges, transit times and availability. We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information or cancel orders if any information in the Service or on any related website is inaccurate at any time without prior notice (including after you have submitted your order).
						</p><p>
						We undertake no obligation to update, amend or clarify information in the Service or on any related website, including without limitation, pricing information, except as required by law. No specified update or refresh date applied in the Service or on any related website, should be taken to indicate that all information in the Service or on any related website has been modified or updated
						</p>

						<h3>Acceptable Use Policy</h3>

						<p class="has-line-data" data-line-start="0" data-line-end="1"><strong>No Inappropriate Content or Users.</strong> Do not use the Services to transmit or store any content or communications (commercial or otherwise) that is illegal, harmful, unwanted, inappropriate, objectionable, confirmed to be criminal misinformation, or otherwise poses a threat to the public. This prohibition includes use of the Services by a hate group or content or communications that originate from a hate group or are exploitive, abusive, or hate speech.</p>
						<p class="has-line-data" data-line-start="2" data-line-end="3"><strong>Prohibited Activities.</strong> Do not use the Services to engage in or encourage any activity that is illegal, deceptive, harmful, violating others’ rights, or harmful to Crazy Ant Labs’s business operations or reputation, including:</p>
						<ul>
						<li class="has-line-data" data-line-start="4" data-line-end="5"><strong>Violations of Laws.</strong> Violating any applicable laws, regulations, or industry standards or guidance (collectively, “Applicable Laws”). This includes violating Applicable Laws requiring (a) consent be obtained prior to transmitting, recording, collecting, or monitoring data or communications or (b) compliance with opt-out requests for any data or communications.</li>
						<li class="has-line-data" data-line-start="5" data-line-end="6"><strong>Interference with the Services.</strong> Interfering with or otherwise negatively impacting any aspect of the Services or any third-party networks that are linked to the Services.</li>
						<li class="has-line-data" data-line-start="6" data-line-end="7"><strong>Reverse Engineering.</strong> Reverse engineering, copying, disassembling, or decompiling the Services.</li>
						<li class="has-line-data" data-line-start="7" data-line-end="9"><strong>Falsification of Identity or Origin.</strong> Creating a false identity or any attempt to mislead others as to the identity of the sender or the origin of any data or communications.</li>
						</ul>
						<p class="has-line-data" data-line-start="9" data-line-end="10"><strong>No Service Integrity Violations.</strong> Do not violate the integrity of the Services, including:</p>
						<ul>
						<li class="has-line-data" data-line-start="11" data-line-end="12"><strong>Bypassing Service Limitations.</strong> Attempting to bypass, exploit, defeat, or disable limitations or restrictions placed on the Services.</li>
						<li class="has-line-data" data-line-start="12" data-line-end="13"><strong>Security Vulnerabilities.</strong> Finding security vulnerabilities to exploit the Services or attempting to bypass any security mechanism or filtering capabilities.</li>
						<li class="has-line-data" data-line-start="13" data-line-end="14"><strong>Disabling the Services.</strong> Any denial of service (DoS) attack on the Services or any other conduct that attempts to disrupt, disable, or overload the Services.</li>
						<li class="has-line-data" data-line-start="14" data-line-end="15"><strong>Harmful Code or Bots.</strong> Transmitting code, files, scripts, agents, or programs intended to do harm, including viruses or malware, or using automated means, such as bots, to gain access to or use the Services.</li>
						<li class="has-line-data" data-line-start="15" data-line-end="17"><strong>Unauthorized Access.</strong> Attempting to gain unauthorized access to the Services.</li>
						</ul>
						<p class="has-line-data" data-line-start="17" data-line-end="18"><strong>Data Safeguards.</strong> Customer is responsible for determining whether the Services offer appropriate safeguards for Customer’s use of the Services, including, but not limited to, any safeguards required by Applicable Laws, prior to transmitting or processing, or prior to permitting End Users to transmit or process, any data or communications via the Services.</p>					

						<p>
						Violations of this AUP, including any prohibited content or communications, may be reported to abuse@mailertogo.com. Customer agrees to immediately report any violation of this AUP to Crazy Ant Labs and provide cooperation, as requested by Crazy Ant Labs, to investigate and/or remedy that violation. Customer is responsible for its End Users’ compliance with this AUP. If Customer or any End User violates this AUP, Crazy Ant Labs may suspend Customer’s use of the Services.
						</p>


						<h3>Modifications To The Service And Prices</h3>

						<p>
						Prices for our products are subject to change without notice.
						</p><p>
						We reserve the right at any time to modify or discontinue the Service (or any part or content thereof) without notice at any time.
						</p><p>
						We shall not be liable to you or to any third-party for any modification, price change, suspension or discontinuance of the Service.
						</p>

						<h3>Service Fees</h3>

						<p>
						We offer several auto-renewal paid plans. Unless otherwise agreed between the parties hereto in writing, our price list, as amended from time to time, for each Paid Plans per Subscription Period is available on our Heroku add-on page.
						</p>
						<p>
						Our Services Fees are non-refundable. We may revise the Services Fees at any time, or impose additional fees or charges.
						</p>

						<h3>Third Party Providers</h3>

						<p>
						We may display third party content, advertisements, links, promotions, logos and other materials through our Service (collectively, “Third Party Content”). We do not control, endorse, sponsor or adopt any third parties referenced on the Services or Third-Party Content, and we make no representations or warranties of any kind regarding such Third-Party Content, including, without limitation, regarding its accuracy or completeness. Your interactions with Third-Party Content, and any third party that provides Third-Party Content, are solely between you and such third parties, and Company is not responsible or liable in any manner for such interactions or Third-Party Content. When you leave the Services and enter a third-party site or service, our terms and policies no longer apply.
						</p>
						<p>You acknowledge that We use third party providers in order to provide the Service. You acknowledge and agree that We are not and shall not be responsible for the availability, performance or security of any such external third party services or resources, and it shall not be held liable for any loss or damage (including loss of data and/or loss of profits), which may be incurred by You, as a result of the lack of availability of, the interruptions or errors in the performance of, and any other problem in those external third party services or resources, or as a result of the lack of availability of, the interruptions or errors in the performance of, and any other problem in the Services provided through such external third party services or resources.
						</p>

						<h3>User Content</h3>

						<p>
						Files uploaded, posted, shared or stored on the Service are collectively known as "User Content".
						</p><p>
						User content may not contain any content that:
						<ol>
						<li>
						is unlawful, libelous, defamatory, harassing, threatening, invasive of privacy or publicity rights, abusive, inflammatory, fraudulent or otherwise objectionable;
						</li>
						<li>
						contains any viruses, corrupted data or other harmful, disruptive or destructive files or content; or
						</li>
						<li>
						in our sole judgment, is objectionable or that restricts or inhibits any other person from using or enjoying the Services, or that may expose Company or others to any harm or liability of any type.
						</li>
						</ol>
						</p><p>
						We do not own, control or endorse any User Content that is transmitted, stored, or processed via our Service. You are solely responsible for any of your User Content and for your use of any interactive features and areas of the Services, and You represent and warrant that (i) You own all intellectual property rights (or have obtained all necessary permissions) to provide your User Content and to grant the licenses in this Agreement; (ii) your User Content will not violate any agreements or confidentiality obligations; and (iii) your User Content will not violate, infringe or misappropriate any intellectual property right or other proprietary right, including the right of publicity or privacy, of any person or entity.
						</p><p>
						You are solely responsible for creating backup copies of and replacing any User Content You post or store on the Services at your sole cost and expense.
						</p>

                        <h3>License from You</h3>
                        <p>
                        Unless you object to this and effectively inform us in writing, you agree that Crazy Ant Labs, in its sole discretion, may use your trade names, trademarks, service marks, logos, domain names and other distinctive brand features in presentations, marketing materials, customer lists, financial reports and Web site listings (including links to your website) for the purpose of advertising or publicizing your use of the Crazy Ant Labs Services.
                        </p>

						<h3>Personal Information</h3>
						<p>
						Your submission of personal information through the store is governed by our Privacy Policy.
						</p>


						<h3>Compliance with EU Law</h3>
						<p>
						For purposes of the GDPR, the parties agree that you are the data controller for the Customer Data, and Crazy Ant Labs is the data processor. Crazy Ant Labs is the data controller for registration, contact, payment, and other information relating to the administration of your account, but not Customer Data. For your rights relating to the information for which Crazy Ant Labs is the data controller, please see our Privacy Policy.
						</p>
						<p>
						The data processing addendum located at <a href="https://mailertogo.com/terms-of-service/dpa">https://mailertogo.com/terms-of-service/dpa</a> is incorporated into our Terms of Service by reference, and governs all processing of personal data of data subjects who are in the European Union, or other data governed by the GDPR which you upload to Mailer To Go. By agreeing to these Terms of Service, you are also agreeing to the terms of the Data Processing Addendum for such data. The Data Processing Addendum does not pertain to data you upload which is not subject to the GDPR. The Standard Contractual Clauses (for processors) are also included with the Data Processing Addendum, in its Annex 2.
						</p>

						<h3>Indemnification</h3>
						<p>
						You agree to indemnify, defend and hold harmless Crazy Ant Labs and our subsidiaries, affiliates, partners, officers, directors, agents, contractors, licensors, service providers, subcontractors, suppliers, interns and employees, harmless from any claim or demand, including reasonable attorneys’ fees, made by any third-party due to or arising out of your breach of these Terms of Service or the documents they incorporate by reference, or your violation of any law or the rights of a third-party.
						</p>

						<h3>Severability</h3>
						<p>
						In the event that any provision of these Terms of Service is determined to be unlawful, void or unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by applicable law, and the unenforceable portion shall be deemed to be severed from these Terms of Service, such determination shall not affect the validity and enforceability of any other remaining provisions.
						</p>

						<h3>Termination</h3>
						<p>
						The obligations and liabilities of the parties incurred prior to the termination date shall survive the termination of this agreement for all purposes. These Terms of Service are effective unless and until terminated by either you or us. 
						</p><p>
						You may terminate these Terms of Service at any time by notifying us that you no longer wish to use our Services, or when you cease using our site.
						</p><p>
						If, in our sole judgment, you fail, or we suspect that you have failed, to comply with any term or provision of these Terms of Service, we also may terminate this agreement at any time without notice and you will remain liable for all amounts due up to and including the date of termination; and/or accordingly may deny you access to our Services (or any part thereof).	
						</p>

						<h3>Changes To Terms of Service</h3>
						<p>
						You can review the most current version of the Terms of Service at any time at this page.
						</p><p>
						We reserve the right, at our sole discretion, to update, change or replace any part of these Terms of Service by posting updates and changes to our website. It is your responsibility to check our website periodically for changes. Your continued use of or access to our website or the Service following the posting of any changes to these Terms of Service constitutes acceptance of those changes.
						</p>

						<h3>Contact information</h3>
						<p>
						If you have any questions regarding these Terms, please contact us at support @ crazyantlabs.com.
						</p>
        	</div>
        </div>
      </div>
    );
  }
}
