import React, { Component } from "react";
import { Card, CardBody, CardHeader, Form, FormGroup, FormText, FormFeedback, Label, Button, Input } from "reactstrap";
import PusherSubscription from "../../components/PusherSubscription";
import ValidationService from "../../services/ValidationService";
import ItemNotFound from '../../components/ItemNotFound';
import Loading from "../../containers/Loading";
import { CheckIcon, PersonIcon } from '@primer/octicons-react';

import {baseInstance as API} from '../../services/apiService';

export default class ProfileCard extends Component {

  constructor(props) {
    super(props);

    this.cancelSignal = API.CancelToken.source();
    this.onPusherEvent = this.onPusherEvent.bind(this);

    this.validationService = ValidationService.getProfileValidationService();

    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleTitleChange = this.handleTitleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      isLoading: true,
      submitting: false,
      submitted: false,
      submittedTimeout: null,
      shouldValidate: false,
      account: null
    }
  }

  componentWillUnmount() {
    if (this.state.submittedTimeout) {
      clearTimeout(this.state.submittedTimeout);
    }
    this.cancelSignal.cancel();
  }

  async componentDidMount() {
    await this.refresh()
  }

  handleNameChange(event) {
    this.setState({
      account: { ...this.state.account, Name: event.target.value },
      shouldValidate: true
    });
  }

  handleTitleChange(event) {
    this.setState({
      account: { ...this.state.account, Title: event.target.value },
      shouldValidate: true
    });
  }

  refresh = async () => {
    try {
      // We use base instance as we don't want error message interceptors to kick for account info retreival
      const account = (await API.get(`account`, {withCredentials: true, cancelToken: this.cancelSignal.token}) ).data;

      this.setState({ isLoading: false, account });

    } catch (error) {
      if (!API.isCancel(error)) {
        this.setState({ isLoading: false });
      }
    }
  }

  setSubmitted = () => {
    if (this.state.submittedTimeout) {
      clearTimeout(this.state.submittedTimeout);
    }
    this.setState({
      submittedTimeout: setTimeout(() => this.setState({submitted: false}), 1000),
      submitted: true
    });
  }

  handleClosed = () => {
    if( this.state.referrer) {
      let that = this;
      setTimeout(() => {
        let history = that.props.history;
        if( history ) {
          history.push(that.state.referrer);
        }
      }, 1);
    }
  }

  async handleSubmit(event) {
    const validation = this.validationService.validate(this.state);

    if( validation.isValid ) {
      try {
        this.setState({
          submitting: true
        });
        await API(`account`, {
            method: "PATCH",
            withCredentials: true,
            data: this.state.account,
            cancelToken: this.cancelSignal.token
          });
        
        this.setSubmitted();
        console.log("Submitted!");
        this.setState({
          submitting: false
        });
      } catch (error) {
        if( !API.isCancel(error) ) {
          this.setState({
            submitting: false
          });
        }
      }
    } else {
      this.setState({
        shouldValidate: true
      });
    }
  }

  onPusherEvent(event, payload) {
    switch(event) {
      case "account-updated":
        this.refresh();
        break;
      case "pusher:subscription_error":
        break;
      default:
        break;
    }
  }

  render() {
    const validation = this.state.shouldValidate ? 
                       this.validationService.validate(this.state) :
                       this.validationService.valid();

    var {className, ...others} = this.props

    className = "ProfileCard " + className;

    return (
      <Card {...others} className={className}>
        <CardHeader>
          <div className="d-flex">
            <div className="flex-grow-1 align-self-center">
              <PersonIcon className="text-primary mr-2" size={20} verticalAlign="text-bottom" />Profile
            </div>
          </div>
        </CardHeader>
        <CardBody>
          { this.state.isLoading && 
            <Loading loading={this.state.isLoading} size="md" /> }
          { !this.state.isLoading &&
            <div>
              <div className="d-flex">
                <div className="flex-grow-1 align-self-center">
                { this.state.account ?
                  <div>
                    <PusherSubscription onEvent={this.onPusherEvent} authEndpoint={`/account/pusher/auth`} channelName={`private-account-${this.state.account.Id}`} events={["pusher:subscription_error", "account-updated"]} />
                    <Form autoComplete="off" onSubmit={(event) => {event.preventDefault()}}>
                      <FormGroup>
                        <Label for="name">Name <span className="text-muted">(optional)</span></Label>
                        <Input invalid={validation["account.Name"].isInvalid} autoComplete="off" name="name" value={this.state.account.Name || ""} onChange={this.handleNameChange} placeholder="Enter your name" />
                        <FormFeedback>{validation["account.Name"].message}</FormFeedback>
                      </FormGroup>
                      <FormGroup>
                        <Label for="name">Email address</Label>
                        <Input disabled name="email" value={this.state.account.Email} placeholder="Your email address" />
                        <FormText>You can manage your avatar using <a href="https://gravatar.com/" target="_blank" rel="noopener noreferrer">Gravatar</a>.</FormText>
                      </FormGroup>
                      {
                        this.state.account && <Button className={this.state.submitting ? "pr-4" : ""} disabled={!validation.isValid || this.state.submitting} color={this.state.submitted ? "success" : "primary"} onClick={this.handleSubmit}>
                          Save profile
                          { this.state.submitting &&
                            <span className="ml-2"><span className="spinner-border spinner-border-sm" /></span> }
                          {
                            this.state.submitted &&
                            <span className="ml-2"><CheckIcon verticalAlign='middle' size="small"/></span> }
                        </Button>
                      }
                    </Form>
                  </div>
                  :
                  <ItemNotFound name="account"/>
                }
                </div>
              </div>
            </div>
          }
        </CardBody>
      </Card>
    );
  }
}
