import React, { Component } from "react";
import "./Compliance.css";

import Picture from "../Picture.js";

export default class Compliance extends Component {

  render() {
    return (
      <div className="Compliance">
        <div className="container-fluid">
          <div className="row p-5">
            <div className="text-center col-sm-12 col-md-12 col-lg-12 offset-sm-0 offset-md-0 offset-lg-0">
              <Picture className="mb-0 ml-5 mr-5" src={"./compliance/gdpr"} alt="GDPR Ready" width={120} height={160} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
