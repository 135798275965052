import React, { Component } from "react";
import { Jumbotron } from "reactstrap";
import "./Testimonials.css";

import Picture from "../Picture.js";

const items = [
  {
    id: 1,
    quote: `We use Mailer To Go to send transactional emails. And we love it.`,
    name: 'Jonathan Siegel',
    title: 'Geek',
    company: 'Xplenty',
    logo: {
      src: './testimonials/xplenty',
      width: 100,
      height: 100,
      alt: 'Xplenty'
    } 
  }
];

export default class Testimonials extends Component {

  renderTestimonials = () => {
    return (
      <div>
        { items.map(
          (item, i) => 
            <React.Fragment key={i}>
            { i !== 0 && <div className="primary-divider"></div> }
            <div className={ i === 0 ? "" : "mt-3" }>
                { 
                  item.logo &&
                  <Picture className="logo rounded mb-5" src={item.logo.src} width={item.logo.width} height={item.logo.height} alt={item.logo.alt} />
                }
                <p className="text-dark lead font-italic">
                  "{item.quote}"
                </p>
                <footer className="mt-5">
                  <div className="font-weight-bold">{item.name}</div>
                  <div className="text-muted">{item.title} at {item.company}</div>
                </footer>
            </div>
            </React.Fragment>
          )
        }
      </div>
    )
  }

  render() {
    return (
      <div className="Testimonials">
        <Jumbotron className="container-fluid">
          <div className="row">
            <div className="col-sm-8 col-md-8 offset-md-2">
              {this.renderTestimonials()}
            </div>
          </div>
        </Jumbotron>
      </div>
    );
  }
}
