import validator from 'validator';

const _ = require('lodash');

// Inspired by https://medium.com/code-monkey/client-side-form-validation-in-react-40e367de47ba
class ValidationService {
  constructor(validations) {
    // Validations is an array of rules specific to a form
    this.validations = validations;
  }

  validate(state) {
    // start out assuming valid
    let validation = this.valid();
    
    // for each validation rule
    this.validations.forEach(rule => {
      // if the field isn't already marked invalid by an earlier rule
      if (!validation[rule.field].isInvalid) {
        // determine the field value, the method to invoke and
        // optional args from the rule definition
        const fieldValue = _.toString(_.get(state, rule.field));
        const args = rule.args || [];
        const validationMethod = typeof rule.method === 'string' ?
                                validator[rule.method] :
                                rule.method
        // call the validationMethod with the current field value
        // as the first argument, any additional arguments, and the
        // whole state as a final argument.  If the result doesn't
        // match the rule.validWhen property, then modify the
        // validation object for the field and set the isValid
        // field to false
        if(validationMethod(fieldValue, ...args, state) !== rule.validWhen) {
          validation[rule.field] = { 
            isInvalid: true, 
            message: rule.message 
          }
          validation.isValid = false;
        }
      }
    });
    return validation;
  }

  // create a validation object for a valid state
  valid() {
    const validation = {}
    
    this.validations.map(rule => (
        validation[rule.field] = { isInvalid: false, message: '' }
    ));
    return { isValid: true, ...validation };
  }

  static getDomainValidationService() {

    const blacklistedDomains = ["herokuapp.com", "herokudns.com"];

    return new ValidationService([
      { 
        field: 'domainName',
        method: 'isEmpty',
        validWhen: false,
        message: "Unfortunately, you can't leave domain name blank."
      },{ 
        field: 'domainName',
        method: 'isFQDN',
        validWhen: true, 
        message: "Unfortunately, domain name doesn't seem to be a fully qualified domain name."
      },{ 
        field: 'domainName',
        method: 'isLength',
        validWhen: true,
        args: [{
          min: 0, max: 2048
        }],
        message: "Unfortunately, domain name can't be longer than 2048 characters." 
      },{ 
        field: 'domainName',
        method: 'matches',
        validWhen: false,
        args: [new RegExp(blacklistedDomains.join("|"), 'i')],
        message: `Unfortunately, top level domain name can't be one of ${blacklistedDomains.join(', ')}.`
      }
    ]);
  }

  static getProfileValidationService() {
    return new ValidationService([{ 
        field: 'account.Name', 
        method: 'isLength',
        validWhen: true, 
        args: [{
          min: 0, max: 80
        }],
        message: "Unfortunately, name can't be longer than 80 characters." 
      },{ 
        field: 'account.Title', 
        method: 'isLength',
        validWhen: true, 
        args: [{
          min: 0, max: 250
        }],
        message: "Unfortunately, title can't be longer than 250 characters." 
      }]);
  }

  static getWebhookValidationService() {
    return new ValidationService([
      { 
        field: 'alias', 
        method: 'isLength',
        validWhen: true, 
        args: [{
          min: 0, max: 80
        }],
        message: "Unfortunately, nickname can't be longer than 80 characters." 
      },{ 
        field: 'url', 
        method: 'isEmpty', 
        validWhen: false, 
        message: "Unfortunately, you can't leave endpoint URL blank."
      },{ 
        field: 'url', 
        method: 'isURL', 
        validWhen: true,
        args: [{
          protocols: ['https'],
          require_protocol: true,
          require_valid_protocol: true,
          disallow_auth: true
        }],
        message: "Unfortunately, endpoint URL doesn't seem to be a valid URL."
      },{ 
        field: 'url', 
        method: 'isLength',
        validWhen: true, 
        args: [{
          min: 0, max: 2048
        }],
        message: "Unfortunately, endpoint URL can't be longer than 2048 characters." 
      },{ 
        field: 'authorizationHeader', 
        method: 'isLength',
        validWhen: true, 
        args: [{
          min: 0, max: 1024
        }],
        message: "Unfortunately, authorization headers can't be longer than 1024 characters."
      },{ 
        field: 'hasSelectedTopic', 
        method: 'equals',
        validWhen: true, 
        args: ["true"],
        message: "You must include at least one topic." 
      }
    ]);
  }

}

export default ValidationService;