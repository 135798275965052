import React, { Component } from "react";
import Octicon from 'react-octicon';

export default class ToastifyCloseButton extends Component {
  render() {
    const { closeToast } = this.props;

    return (
      <Octicon onClick={closeToast} name="x" size="sm"/>
    );
  }
}
