import React, { Component } from "react";
import { Jumbotron } from "reactstrap";
import CtaLink from "../../components/CtaLink";

import "./GetStarted.css";

export default class GetStarted extends Component {

  render() {
    return (
      <div className="GetStarted pb-1">
        <Jumbotron className="container bg-primary">
          <div className="row">
            <div className="text-center col-sm-12 col-md-6 offset-md-3">
              <h2 className="text-white mb-2">Ready to get started?</h2>
              <p className="text-white mb-4 lead">Be up and running with sending emails in seconds.</p>
              <CtaLink href={ process.env.REACT_APP_SIGNUP_URL } className="btn btn-lg btn-white" text="Get started" />
            </div>
          </div>
        </Jumbotron>
      </div>
    );
  }
}