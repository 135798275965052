import React, { Component } from "react";
import { Link } from "react-router-dom";
import { AlertIcon } from '@primer/octicons-react';
import { page } from '../../services/analytics.js'

export default class AuthError extends Component {
  constructor(props) {
    super(props);

    const searchParams = new URLSearchParams(this.props.location.search);
    this.state = {
      error: searchParams.get("error_description")
    }
  }

  componentDidMount() {
    page();
  }
  
  render() {
    return (
      <div className="bg-white py-5">
        <div className="text-center py-5">
          <AlertIcon size={60} className="text-danger mb-4" />
          <h5>{
            this.state.error ?
            <span>{this.state.error}</span>
            :
            <span>Something went wrong.</span>
          }</h5>
          <p>Perhaps head back <Link to="/">home</Link>?</p>
        </div>
      </div>
    );
  }
}