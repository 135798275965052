import React, { Component } from "react";
import { Fade, Button } from "reactstrap";
import { NotificationMessage } from "../NotificationMessage";
import { baseInstance as API } from '../../services/apiService';

import { toast } from 'react-toastify';

export default class EmailVerificationMessage extends Component {
  constructor(props) {
    super(props);

    this.cancelSignal = API.CancelToken.source();
    this.onEmailVerificationResend = this.onEmailVerificationResend.bind(this);

    this.state = {
      email: props.email,
      submitting: false
    };
  }

  componentWillUnmount() {
    this.cancelSignal.cancel();
  }

  async onEmailVerificationResend(event) {
    try {
      this.setState({
        submitting: true
      });
      let account = (await API(`/account/email/confirmations`, {
        method: "POST",
        withCredentials: true,
        cancelToken: this.cancelSignal.token
      })).data;
      
      //this.setSubmitted();
      this.setState({
        submitting: false
      });

      toast.success(`We sent an email with a verification link to ${account.Email}.`, {
        icon: true,
        hideProgressBar: false
      });
    } catch (error) {
      if( !API.isCancel(error) ) {
        this.setState({
          submitting: false
        });
      }
    }
  }


  render() {
    let { className, color } = this.props;
    className = "EmailVerificationMessage " + (className ? className : "");

    if( color ) {
      className = className + " bg-" + color;
    }

    return (
      <Fade in={true}>
        <NotificationMessage className={className}>
          <span className="mr-3">To keep your account secure, please confirm <b>{this.state.email}</b> is your email address. We sent you an email to complete the process.</span>
          <Button className={this.state.submitting ? "pr-4 ml-1" : "ml-1"} disabled={this.state.submitting} color="white" size="sm" outline onClick={this.onEmailVerificationResend}>
            Resend confirmation email
            { this.state.submitting &&
              <span className="ml-2"><span className="spinner-border spinner-border-sm" /></span> }
          </Button>
        </NotificationMessage>
      </Fade>
    );
  }
}