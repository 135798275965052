import React, { Component } from "react";
import { LocationIcon } from '@primer/octicons-react'
import "./ItemNotFound.css";

export default class ItemNotFound extends Component {

  render() {
    let { name } = this.props;

    if( !name ) { name = "item" }
    return (
      <div className="ItemNotFound text-center p-5 align-self-center">
        <div className="text-center">
          <LocationIcon className="text-muted mb-4" size="medium" />
          <h5>Sorry, we couldn't find this {name}.</h5>
        </div>
      </div>
    );
  }
}
