import React, { Component } from "react";
import { Table, Card, CardBody, CardHeader, Fade, UncontrolledTooltip } from "reactstrap";
import ItemNotFound from '../../components/ItemNotFound';
import Loading from "../../containers/Loading";
import Moment from 'react-moment';

import { HistoryIcon } from '@primer/octicons-react';

import {baseInstance as API} from '../../services/apiService';

const _ = require('lodash');

export default class LoginsCard extends Component {

  constructor(props) {
    super(props);

    this.cancelSignal = API.CancelToken.source();

    this.state = {
      isLoading: true,
      limit: props.limit || 5,
      logins: null
    }
  }

  componentWillUnmount() {
    this.cancelSignal.cancel();
  }

  async componentDidMount() {
    try {
      // We use base instance as we don't want error message interceptors to kick for account info retreival
      const logins = (await API.get(`account/logins?limit=${this.state.limit}`, {withCredentials: true, cancelToken: this.cancelSignal.token}) ).data;

      this.setState({ isLoading: false, logins });

    } catch (error) {
      if (!API.isCancel(error)) {
        this.setState({ isLoading: false });
      }
    }
  }

  getLocation = (login) => {
    const countryIsoCode = _.get(login, 'Geo.country');
    const city = _.get(login, 'Geo.city');
    if( countryIsoCode || city ) {
      return _.join([city, countryIsoCode], " - ");
    } else {
      return "Unknown";
    }

  }

  getIpAddress = (login) => {
    return _.get(login, 'IpAddress', "Unknown");
  }

  getClient = (login) => {
    let userAgent = _.get(login, 'UserAgent');
    if( userAgent ) {
      let browser = _.get(userAgent, 'ua.family');
      let osVersion = _.join(_.at(userAgent, ['os.major', 'os.minor', 'os.patch']), ".");
      let os = _.join( [_.get(userAgent, 'os.family'), osVersion], " " );

      if( browser || os ) {
        return _.join( [browser, os], " - " );
      } else {
        return "Unknown";
      }
    } else {
      return "Unknown";
    }
  }

  render() {
    var {className} = this.props

    className = "LoginsCard " + className;

    return (
      <Card id="logins" className={className}>
        <CardHeader>
          <div className="d-flex">
            <div className="flex-grow-1 align-self-center">
              <HistoryIcon className="text-primary mr-2" size={20} verticalAlign="text-bottom" />History
            </div>
          </div>
        </CardHeader>
        <CardBody>
          { this.state.isLoading && 
            <Loading loading={this.state.isLoading} size="md" /> }
          { !this.state.isLoading &&
            <Fade in={true}>
              <div className="d-flex">
                <div className="flex-grow-1 align-self-center">
                  <h5>Logins</h5>
                  <div className="text-muted">
                    Your recent login activity.
                  </div>
                </div>
              </div>
              <div className="mt-3 d-flex">
                <div className="flex-grow-1 align-self-center">
                  { this.state.logins ?
                    <div>
                      {
                        this.state.logins.length > 0 ?
                        <Table className="border" size="sm">
                          <thead className="thead-light">
                            <tr>
                              <th>Location</th>
                              <th>Client</th>
                              <th>IP Address</th>
                              <th>Time</th>
                            </tr>
                          </thead>
                          <tbody>
                            { this.state.logins.map(
                              (login, i) => 
                                <tr key={i}>
                                  <td className="align-middle">{this.getLocation(login)}</td>
                                  <td className="align-middle">{this.getClient(login)}</td>
                                  <td className="align-middle">{this.getIpAddress(login)}</td>
                                  <td className="align-middle">
                                    <Moment id={"login-time-" + login.Id} fromNow date={login.CreatedAt} />
                                    <UncontrolledTooltip boundariesElement="viewport" placement="top" target={"login-time-" + login.Id}>
                                      <Moment format="ddd, MMM Do, YYYY [at] HH:mm z" date={login.CreatedAt} />
                                    </UncontrolledTooltip>
                                  </td>
                                </tr>
                              )
                            }
                          </tbody>
                        </Table>
                        :
                        <div className="text-center p-4 align-self-center bg-light">
                          <div className="text-center">
                            <div className="font-weight-bold">No matching logins</div>
                          </div>
                        </div>
                      }
                    </div>
                    :
                    <ItemNotFound name="account"/>
                  }
                </div>
              </div>
            </Fade>
          }
        </CardBody>
      </Card>
    );
  }
}
