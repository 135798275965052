import React, { Component } from "react";
import { Jumbotron } from "reactstrap";
import { page } from '../services/analytics.js'
import Compliance from "../components/home/Compliance";
import SEO from '../components/SEO';
import "./Security.css";

export default class Security extends Component {

  componentDidMount() {
    page();
  }

  render() {
    return (
      <div className="Security">
        <SEO title="Security" description="Keeping our services secure is fundamental to the nature of our business" />
        <Jumbotron className="text-center container-fluid mb-2">
          <div className="row">
            <div className="mt-3 col-sm-8 col-md-8 offset-md-2">
              <h1>Security at Mailer To Go</h1>
              <p className="mt-4 lead">We understand the sensitivity of your data and we take all the necessary steps to safeguard it. Keeping our services secure is fundamental to the nature of our business. That's why security is our number one priority.</p>
              <div className="primary-divider" />
            </div>
          </div>
        </Jumbotron>
        <div className="container-fluid">
          <div className="row">
            <div className="mb-5 col-10 col-md-10 col-xl-10 offset-1 offset-md-1 offset-xl-1 text-justify">
              <h3>Data Encryption</h3>
              <p>
              We use SSL/TLS encryption on all our websites and microservices satisfying the highest security and data protection standards. We regularly verify our security certificates and encryption algorithms to keep your data safe.
              </p>
              <h3>Your data</h3>
              <p>
              The emails you send through Mailer To Go is encrypted in transit.  
              Credential user names are encrypted and passwords are hashed.
              </p>
              <p>
              We do not access or use your content for any purpose without your consent. 
              We never use your content or derive information from it for marketing or advertising. 
              </p>
              <p>
              We do not disclose customer information unless we're required to do so to comply with a legally valid and binding order. 
              Unless prohibited from doing so or there is clear indication of illegal conduct in connection with the use of our products or services, we notify customers before disclosing content information.
              </p>
              <h3>Physical Security</h3>
              <p>
                Our physical infrastructure is hosted on Amazon Web Services' (AWS) data centers. The following is a partial list of assurance programs AWS complies with:
                <p>
                <br/>* SOC 1/ISAE 3402, SOC 2, SOC 3
                <br/>* FISMA, DIACAP, and FedRAMP
                <br/>* PCI DSS Level 1
                <br/>* ISO 9001, ISO 27001, ISO 27017, ISO 27018
                </p>
                For additional information see: <a href="https://aws.amazon.com/security/">https://aws.amazon.com/security/</a>
              </p>
              <h3>Network Security</h3>
              <p>
              AWS Security Groups (virtual firewalls) are utilized to restrict access to our network from external networks and between systems internally. By default, all access is denied and only explicitly allowed ports and protocols are allowed based on business requirement. Each system is assigned a security group based on its function.
              </p>
              <h3>System Security</h3>
              <p>
              Backend access to the OS level and AWS console is limited to Crazy Ant Labs staff and requires key authentication and multi factor authentication. 
              </p>
              <p>
              We follow the principle of least privilege both internally by restricting our staff's access to the minimum permissions needed to perform their work, as well as externally by asking the user for the minimum permissions required by our service when accessing 3rd party systems on the user's behalf.
              </p>
              <h3>Report Issues</h3>
              <p>
              If you find a bug or security issue on our website, please let us know about it by sending an immediate e-mail to <a href="mailto:security@mailertogo.com">security at mailertogo.com</a>.
              </p>
            </div>
          </div>
        </div>
        <div>
        <Jumbotron className="text-center container-fluid bg-light">
          <div className="row">
            <div className="mt-3 col-sm-8 col-md-8 offset-md-2">
              <h2>Security questions or issues?</h2>
              <p className="mt-4 lead">
                If you'd like more detail about our security processes and practices, please email us: <a href="mailto:support@crazyantlabs.com">support at crazyantlabs.com</a>.
              </p>
            </div>
          </div>
        </Jumbotron>
        </div>
        <Compliance />
      </div>
    );
  }
}
