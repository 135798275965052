import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Badge, Button, NavItem, Dropdown, DropdownItem, DropdownToggle, DropdownMenu, UncontrolledDropdown } from "reactstrap";
import { ChevronDownIcon, ArrowRightIcon, QuestionIcon, BookIcon, SignOutIcon, OrganizationIcon, CodespacesIcon } from '@primer/octicons-react';

import EmailVerificationMessage from "./signup/EmailVerificationMessage";
import { withNotifications } from "./NotificationBar";
import { baseInstance as API } from '../services/apiService';
import { identify, group } from '../services/analytics.js';
import CtaLink from "./CtaLink";
import LoginNavItem from "./LoginNavItem";
import PusherSubscription from "../components/PusherSubscription";

import "./AccountNavItem.css";

const roles = require('../constants/roles');

const gravatar = require('gravatar');
const _ = require('lodash');

const Mousetrap = require('../libs/mousetrap-lib');

class AccountNavItem extends Component {

  constructor(props) {
    super(props);

    this.cancelSignal = API.CancelToken.source();
    this.onPusherEvent = this.onPusherEvent.bind(this);
    this.toggle = this.toggle.bind(this);
    this.refresh = this.refresh.bind(this);
    this.toggleShortcutsModal = this.toggleShortcutsModal.bind(this);

    this.state = {
      submitting: false,
      account: null,
      organizationId: null,
      isLoading: true,
      isOpen: props.defaultOpen || false
    };
  }

  componentWillUnmount() {
    this.cancelSignal.cancel();
    if( this.state.account ) {
      Mousetrap.unbind('=');
    }
  }

  toggleShortcutsModal = () => {
    Mousetrap.trigger('?');
  };

  toggle() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  onEditProfile = () => {
    this.toggle();
    this.props.history.push(`/settings/account`);
  }

  onChangeOrganization = () => {
    this.toggle();
    this.props.history.push(`/organizations`);
  }

  emailVerificationNotification = (email) => {
    return <EmailVerificationMessage color="danger" className="text-white" email={email} />
  }

  async refresh() {
    try {
      this.setState({ isLoading: true});

      // We use base instance as we don't want error message interceptors to kick for account info retreival
      let [account, organizations] = (await Promise.all([
        (API.get(`account`, {withCredentials: true, cancelToken: this.cancelSignal.token })),
        (API.get(`account/organizations`, {withCredentials: true, cancelToken: this.cancelSignal.token }))
      ])).map( res => { return res.data; });

      organizations = _.sortBy( organizations, ["Name", "CreatedAt"] ) ;

      if( account && account.Id ) {
        let context = null;

        if( !account.EmailConfirmed ) {
          this.props.setNotificationComponent(this.emailVerificationNotification(account.Email));
        } else {
          this.props.setNotificationComponent(null);
        }

        // https://segment.com/docs/connections/destinations/catalog/intercom/
        // Intercom identity verification
        if( account.IntercomHash ) {
          context = {
            Intercom: {
              user_hash: account.IntercomHash
            }
          }
        }

        // Call client-side identify
        identify(account.Id, _.omitBy({
          email: _.get(account, 'Email'),
          name: _.get(account, 'Name')
        }, _.isNil), context);

        let currentOrganizationId = _.last( this.props.location.pathname.match("^/organizations/(.*)/.*$") );

        if( currentOrganizationId ) {
          let currentOrganization = _.find(organizations, { Id: currentOrganizationId } );
          
          if( currentOrganization && currentOrganization.Id ) {
            group(currentOrganization.Id, {
              name: _.get(currentOrganization, 'Name')
            }, context);
          }
        }
      }

      this.setState({ isLoading: false, account, organizations });

    } catch (error) {
      if (!API.isCancel(error)) {
        this.setState({ isLoading: false });
      }
    }
  }


  async componentDidMount() {
    await this.refresh();

    this.props.history.listen( location => {
      this.setOrganizationId(location);
    });

    // Extract organization ID to check if we should provide app links
    this.setOrganizationId(this.props.location);

    if( this.state.account ) {
      Mousetrap.bind('=', (e) => {
        this.props.history.push(`/organizations`);
      });
    }
  }

  setOrganizationId = (location) => {
    this.setState({
      organizationId: _.last( location.pathname.match("^/organizations/(.*)/.*$") )
    });
  }

  currentOrganization = () => {
    return _.find(this.state.organizations, { Id: this.state.organizationId });
  }

  role = (organization) => {
    let roleId = _.get(organization, 'Role', 'admin'); // Default admin role

    return _.get( _.find(roles, { id: roleId }), 'name', '-');
  }

  signout = async () => {
    try {
      // Clean state
      this.setState({
        account: null
      });

      window.location.href = process.env.REACT_APP_LOGOUT_URL;
    } catch(e) {
      if( !API.isCancel(e) ) {
        console.log('An error occurred signing out: ', e);
        this.setState({
          account: null
        });
      }
    }
  }

  onPusherEvent(event, payload) {
    switch(event) {
      case "account-updated":
        this.refresh();
        break;
      case "account-deleted":
        this.signout();
        break;
      case "pusher:subscription_error":
        break;
      default:
        break;
    }
  }

  render() {
    const avatarName = _.first(_.split(_.get(this.state, 'account.Email', "Anonymous"), '@'));
    const currentOrg = this.currentOrganization();
    const currentPlan = _.get(currentOrg, "Plan");

    return (
      this.state.isLoading ?
      ""
      :
      <React.Fragment>
      {
        this.state.account ?
        <React.Fragment>
          <PusherSubscription onEvent={this.onPusherEvent} authEndpoint={`/account/pusher/auth`} channelName={`private-account-${this.state.account.Id}`} events={["pusher:subscription_error", "account-updated", "account-deleted"]} />
          {
            !this.state.organizationId && this.state.organizations && this.state.organizations.length === 1 &&
            <NavItem>
              <Link className="nav-link dashboard-button" to={`/organizations/${this.state.organizations[0].Id}/dashboard`}>Dashboard</Link>
            </NavItem>
          }
          {
            this.state.organizations && this.state.organizations.length > 1 &&
            <UncontrolledDropdown nav inNavbar className="OrganizationsNavItem">
              <DropdownToggle className="nav-link dashboard-button" nav left="true">
                Dashboard <ChevronDownIcon size="small" verticalAlign='middle'/>
              </DropdownToggle>
              <DropdownMenu right={true}>
                { this.state.organizations.map(
                  (organization, i) => 
                    <DropdownItem href={`/organizations/${organization.Id}/dashboard`} tag="a" key={i}>
                      <div className="d-flex">
                        <div className="flex-grow-1 align-self-center">
                          <div className="org-name d-flex align-items-center">
                            {organization.Name}
                            {
                              organization.Id === this.state.organizationId &&
                              <Badge className="text-success-dark ml-2" color="success-light">Current</Badge>
                            }
                          </div>
                          <div className="small org-app-name">{_.get(organization, "Partner.Addon.billing_entity.name", "")}</div>
                        </div>
                        <div className="align-self-center ml-3">
                          <ArrowRightIcon className="text-primary" size={16} verticalAlign='text-bottom'/>
                        </div>
                      </div>
                    </DropdownItem>
                  )
                }
              </DropdownMenu>
            </UncontrolledDropdown>
          }
          <Dropdown nav inNavbar isOpen={this.state.isOpen} toggle={this.toggle} className="AccountNavItem">
            <DropdownToggle nav>
              <img className="avatar" src={ gravatar.url(this.state.account.Email, {s: '60', d: `https://ui-avatars.com/api/${avatarName}/60/ffb822/343a40/1` }, true) } alt="" />
            </DropdownToggle>
            <DropdownMenu right={true}>
              <div className={(currentOrg ? "" : "mb-2") + " details border-bottom bg-light"}>
                <div className="text-center">
                  <div className="avatar-container"><img className="avatar" src={ gravatar.url(this.state.account.Email, {s: '96', d: `https://ui-avatars.com/api/${avatarName}/96/ffb822/343a40/1`}, true) } alt="" /></div>
                  {
                    this.state.account.Name &&
                    <div className="display-name text-dark">{this.state.account.Name}</div>
                  }
                  <div className="text-muted small">{this.state.account.Email}</div>
                  <Button size="sm" color="secondary" outline onClick={this.onEditProfile} className="mt-2 mb-2" >Account settings</Button>
                </div>
              </div>
              {
                currentOrg ?
                <div className="px-4 py-3 border-bottom mb-2">
                  <div className="org-name">{currentOrg.Name}</div>
                  {
                    currentPlan &&
                    <div className="text-muted small">
                      {currentPlan.Name} plan
                    </div>
                  }
                  <small className="text-muted">
                    {this.role(currentOrg)}
                  </small>
                  <div><Button size="sm" color="link" onClick={this.onChangeOrganization} className="p-0 border-0" >Switch organization</Button></div>
                </div>
                :
                <DropdownItem onClick={this.onChangeOrganization}>
                  <div className="d-flex">
                    <div className="flex-grow-1 align-self-center">
                      <OrganizationIcon size={18} verticalAlign='text-bottom' className="mr-2" />Switch organization
                    </div>
                    <div className="ml-3 align-self-center">
                      <kbd>=</kbd>
                    </div>
                  </div>
                </DropdownItem>
              }
              <DropdownItem tag="a" target="_blank" rel="noopener noreferrer" href="https://devcenter.heroku.com/articles/mailertogo" >
                <BookIcon size={18} verticalAlign='text-bottom' className="mr-2" />Documentation
              </DropdownItem>
              <DropdownItem onClick={(event) => { if( window.Intercom ) { event.preventDefault(); window.Intercom('showNewMessage'); } }}
                tag="a" target="_blank" rel="noopener noreferrer" href="mailto:support@mailertogo.com" >
                <div className="d-flex">
                  <div className="flex-grow-1 align-self-center">
                    <QuestionIcon size={18} verticalAlign='text-bottom' className="mr-2" />Contact support
                  </div>
                  <div className="ml-3 align-self-center">
                    <kbd>s</kbd>
                  </div>
                </div>
              </DropdownItem>
              <DropdownItem  onClick={this.toggleShortcutsModal}>
                <div className="d-flex">
                  <div className="flex-grow-1 align-self-center">
                    <CodespacesIcon size={18} verticalAlign='text-bottom' className="mr-2" />Keyboard shortcuts
                  </div>
                  <div className="ml-3 align-self-center">
                    <kbd>?</kbd>
                  </div>
                </div>
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem className="danger" onClick={this.signout}>
                <SignOutIcon size={18} verticalAlign='text-bottom' className="mr-2" />Sign out
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </React.Fragment>
        :
        (
          !this.state.organizationId &&
          <React.Fragment>
            { this.props.login && 
              <LoginNavItem text={ !this.props.signup ? "Already have an account?" : "Sign in" } />
            }
            { this.props.signup && 
              <NavItem>
                <CtaLink href={ process.env.REACT_APP_SIGNUP_URL } className="nav-link dashboard-button" text="Get Addon" />
              </NavItem>
            }
          </React.Fragment>
        )
        
      }
      </React.Fragment>
    );
  }
}

export default withRouter(withNotifications(AccountNavItem));
