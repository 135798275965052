module.exports = Object.freeze([{
  id: "us-east-2",
  name: "US East (Ohio)",
  available: false
}, {
  id: "us-east-1",
  name: "US East (N. Virginia)",
  available: false
}, {
  id: "us-west-1",
  name: "US West (N. California)",
  available: true
}, {
  id: "us-west-2",
  name: "US West (Oregon)",
  available: false
}, {
  id: "ap-south-1",
  name: "Asia Pacific (Mumbai)",
  available: false
}, {
  id: "ap-northeast-2",
  name: "Asia Pacific (Seoul)",
  available: false
}, {
  id: "ap-southeast-1",
  name: "Asia Pacific (Singapore)",
  available: false
}, {
  id: "ap-southeast-2",
  name: "Asia Pacific (Sydney)",
  available: false
}, {
  id: "ap-northeast-1",
  name: "Asia Pacific (Tokyo)",
  available: false
}, {
  id: "ca-central-1",
  name: "Canada (Central)",
  available: false
}, {
  id: "eu-central-1",
  name: "EU (Frankfurt)",
  available: false
}, {
  id: "eu-west-1",
  name: "EU (Ireland)",
  available: false
}, {
  id: "eu-west-2",
  name: "EU (London)",
  available: false
}, {
  id: "eu-west-3",
  name: "EU (Paris)",
  available: false
}, {
  id: "eu-north-1",
  name: "EU (Stockholm)",
  available: false
}, {
  id: "sa-east-1",
  name: "South America (São Paulo)",
  available: false
}]);
