require('mousetrap');

// Require global binding
require('mousetrap-global-bind');

/* global Mousetrap:true */
(function(Mousetrap) {
    if (! Mousetrap) {
        return;
    }
    var _originalStopCallback = Mousetrap.prototype.stopCallback;

    Mousetrap.prototype.stopCallback = function(e, element, combo, sequence) {
        var self = this;

        if( document.querySelector('body').classList.contains('modal-open') ) {
          return true;
        }

        if( ('keyboard_shortcuts' in localStorage) && localStorage.keyboard_shortcuts !== 'true' ) {
          return true;
        }

        return _originalStopCallback.call(self, e, element, combo);
    };

    Mousetrap.init();
}) (typeof Mousetrap !== "undefined" ? Mousetrap : undefined);

module.exports = Mousetrap;