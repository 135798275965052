const {flag} = require('country-emoji');

module.exports = Object.freeze({
  "us-east-1": "US",
  "us-west-1": "US",
  "us-west-2": "US",
  "eu-west-1": "EU",
  "eu-central-1": "EU",
  "ap-southeast-1": "SG",
  "ap-southeast-2": "AU",
  "ap-northeast-1": "JP",

  getByRegionId: function(regionId) {
    return flag(this[regionId]);
  }
});
