import React, { Component } from "react";
import { Jumbotron } from "reactstrap";
import { page, track, reset } from '../../services/analytics.js'
import Loading from "../Loading";
import API from '../../services/apiService';
import Error from "../Error";
import SEO from '../../components/SEO';

export default class Logout extends Component {

  constructor(props) {
    super(props);

    this.cancelSignal = API.CancelToken.source();

    this.state = {
      isError: false
    };
  }

  logout = async () => {
    try {
      reset();

      await API.post(`account/logout`, {}, {
        withCredentials: true, // We need this to set state cookie
        cancelToken: this.cancelSignal.token 
      });

      window.location.href = process.env.REACT_APP_BASE_URL;
    } catch(e) {
      if( !API.isCancel(e) ) {
        console.log('An error occurred logging out: ', e);
        this.setState({
          isError: true
        });
      }
    }
  }
  
  componentDidMount() {
    page();

    track('Signed Out');

    this.logout();
  }

  render() {
    return (
      <div>
        <SEO title="Logout" />
        { this.state.isError && <Error /> }
        {
          !this.state.isError &&
          <Jumbotron className="mb-4 text-center">
            <Loading className="my-5 py-5" loading={true} text="Hold tight - logging you out" /> 
          </Jumbotron>
        }
      </div>
    );
  }
}