import React, { Component } from "react";
import { Table, Card, CardBody, CardHeader, Fade } from "reactstrap";
import ItemNotFound from '../../components/ItemNotFound';
import Loading from "../../containers/Loading";
import { withRouter } from "react-router-dom";

import { OrganizationIcon, ArrowRightIcon } from '@primer/octicons-react';
import {baseInstance as API} from '../../services/apiService';

const regionFlags = require('../../constants/regionFlags');
const regions = require('../../constants/regions');
const roles = require('../../constants/roles');

const _ = require('lodash');

class OrganizationsCard extends Component {

  constructor(props) {
    super(props);

    this.cancelSignal = API.CancelToken.source();

    this.state = {
      isLoading: true,
      organizations: null
    }
  }

  componentWillUnmount() {
    this.cancelSignal.cancel();
  }

  async componentDidMount() {
    try {
      // We use base instance as we don't want error message interceptors to kick for account info retreival
      let organizations = (await API.get(`account/organizations`, {withCredentials: true, cancelToken: this.cancelSignal.token}) ).data;
      organizations = _.sortBy( organizations, ["Name", "CreatedAt"] ) ;

      this.setState({ isLoading: false, organizations });

    } catch (error) {
      if (!API.isCancel(error)) {
        this.setState({ isLoading: false });
      }
    }
  }

  role = (organization) => {
    let roleId = _.get(organization, 'Role', 'admin'); // Default admin role

    return _.get( _.find(roles, { id: roleId }), 'name', '-');
  }

  render() {
    var {className} = this.props

    className = "OrganizationsCard " + className;
    let regionsMap = _.keyBy(regions, "id");

    return (
      <Card id="organizations" className={className}>
        <CardHeader>
          <div className="d-flex">
            <div className="flex-grow-1 align-self-center">
              <OrganizationIcon className="text-primary mr-2" size={20} verticalAlign="text-bottom" />Organizations
            </div>
          </div>
        </CardHeader>
        <CardBody>
          { this.state.isLoading && 
            <Loading loading={this.state.isLoading} size="md" /> }
          { !this.state.isLoading &&
            <Fade in={true}>
              <div className="d-flex">
                <div className="flex-grow-1 align-self-center">
                  <h5>Your organizations</h5>
                  <div className="text-muted">
                    The list of organizatinos of which you are a member.
                  </div>
                </div>
              </div>
              <div className="mt-3 d-flex">
                <div className="flex-grow-1 align-self-center">
                  { this.state.organizations ?
                    <div>
                      {
                      this.state.organizations && this.state.organizations.length > 0 ?
                        <Table responsive hover className="border" size="sm">
                          <thead className="thead-light">
                            <tr>
                              <th>Name</th>
                              <th>Role</th>
                              <th>Region</th>
                              <th>Plan</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            { _.sortBy(this.state.organizations, ["CreatedAt"]).map(
                              (organization, i) => 
                                <tr className="cursor-pointer" onClick={(event) =>{ this.props.history.push(`/organizations/${organization.Id}/dashboard`); }} key={i}>
                                  <td className="align-middle">
                                    <span>{organization.Name}</span>
                                    {
                                      _.get(organization, "Partner.Addon.billing_entity.name", null) &&
                                      <div className="small text-muted">Billed to <span className="text-purple">{_.get(organization, "Partner.Addon.billing_entity.name", "")}</span></div>
                                    }
                                  </td>
                                  <td className="align-middle">
                                    {this.role(organization)}
                                  </td>
                                  <td className="align-middle">
                                    <span className="mr-1">{ regionFlags.getByRegionId(_.get(organization, 'Region')) }</span>
                                    { regionsMap[_.get(organization, 'Region')].name }
                                  </td>
                                  <td className="align-middle">
                                    <span className="d-flex align-items-center">
                                    {organization.Plan.Name}
                                    </span>
                                  </td>
                                  <td className="align-middle">
                                    <ArrowRightIcon className="text-primary" size={16} verticalAlign='middle'/>
                                  </td>
                                </tr>
                              )
                            }
                          </tbody>
                        </Table>

                        :
                        <div className="text-center p-4 align-self-center bg-light">
                          <div className="text-center">
                            <div className="font-weight-bold">No matching organizations</div>
                          </div>
                        </div>
                      }
                    </div>
                    :
                    <ItemNotFound name="account"/>
                  }
                </div>
              </div>
            </Fade>
          }
        </CardBody>
      </Card>
    );
  }
}

export default withRouter(OrganizationsCard);