import React, { Component } from "react";
import Logo from "../images/logo.png";
import {Helmet} from "react-helmet";

const util = require('util');

export default class SEO extends Component {
  render() {
    var { title, description, canonical } = this.props;

    if( title == null ) { title = process.env.REACT_APP_SITE_TITLE; }
    if( description == null ) { description = process.env.REACT_APP_SITE_DESCRIPTION; }
    // meta contents won't go through Helmet's titleTemplate, so make sure to have it consistent
    var formattedTitle = util.format(process.env.REACT_APP_SITE_TITLE_TEMPLATE, title);

    if( canonical == null ) {
      // Remove query string
      canonical = window.location.href.split('?')[0];
      // Remove trailing slash
      canonical = canonical.replace(/\/$/, '');
    }

    return (
      <Helmet defaultTitle={process.env.REACT_APP_SITE_TITLE} titleTemplate={process.env.REACT_APP_SITE_TITLE_TEMPLATE}>
        <title>{title}</title>

        <meta name="description" content={description} />

        {
          canonical && <link rel="canonical" href={canonical} />
        }

        <meta property="og:site_name" content={process.env.REACT_APP_SITE_NAME} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={formattedTitle} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={canonical} />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image" content={Logo} />
        <meta property="og:image:width" content="250" />
        <meta property="og:image:height" content="250" />
        <meta property="og:image:alt" content={process.env.REACT_APP_SITE_NAME} />
        
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={formattedTitle} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:url" content={canonical} />
        <meta name="twitter:domain" content={process.env.REACT_APP_DOMAIN} />
        <meta name="twitter:creator" content={process.env.REACT_APP_TWITTER_HANDLE} />
        <meta name="twitter:site" content={process.env.REACT_APP_TWITTER_HANDLE} />
        <meta name="twitter:image" content={Logo} />
        
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "publisher": {
              "@type": "Organization",
              "name": "${formattedTitle}",
              "url": "${process.env.REACT_APP_BASE_URL}",
              "logo": {
                "@type": "ImageObject",
                "url": "${Logo}",
                "width": 250,
                "height": 250
              }
            },
            "url": "${canonical}",
            "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": "${process.env.REACT_APP_BASE_URL}"
            },
            "description": "${description}"
          }
        `}</script>
      </Helmet>
    );
  }
}